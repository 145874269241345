import React, { useState, useRef, useEffect } from "react";
import { useCalendar } from "./CalendarContext";
import { Row, Col, Select, Form, Spin, Button, InputNumber, Alert } from "antd";
import Day from "./Day";
import TextArea from "antd/lib/input/TextArea";
import ActivitySelect from "./ActivitySelect";
import SlotSelect from "./SlotSelect";
import IBookingModel from "../../interfaces/IBookingModel";
import { useApp } from "../../AppContext";
import moment from "moment";
import { Redirect } from "react-router-dom";

// get current month
const currentMonth = moment().month();

function Calendar() {
    const { bookingModel, setBookingModel, setRedirectTo } = useApp();
    const {
        handlePrevWeekClick,
        handleNextWeekClick,
        handleMonthSelect,
        daySelected,
        startTimeSelected,
        endTimeSelected,
        daysOfWeek,
        slotsSelected,
        slotSize,
        activitySelected,
        activityNameSelected,
        selectedSlot,
        date,
    } = useCalendar();
    const [months, setMonths] = useState<Array<IMonthItem>>(null);

    const [numberOfPassengers, setNumberOfPassengers] = useState<number>(0);
    const [numberOfWheelChairPassengers, setNumberOfWheelChairPassengers] =
        useState<number>(0);
    const [
        numberOfNonWheelchairDisabledPassengers,
        setNumberOfNonWheelchairDisabledPassengers,
    ] = useState<number>(0);
    const [additionalInformation, setAdditionalInformation] =
        useState<string>("");
    const [continueToConfirm, setContinueToConfirm] = useState<boolean>(false);
    const [form] = Form.useForm();

    const handleBookingFormClick = async () => {
        try {
            const values = await form.validateFields();
            if (values) {
                const bm: IBookingModel = {
                    date: daySelected,
                    activityId: activitySelected,
                    activityType: activityNameSelected,
                    slotSize: slotSize,
                    timeStart: startTimeSelected,
                    timeEnd: endTimeSelected,
                    numberOfPassengers: numberOfPassengers,
                    numberOfWheelChairs: numberOfWheelChairPassengers,
                    numberOfNonWheelchairDisabled:
                        numberOfNonWheelchairDisabledPassengers,
                    additionalInformation: additionalInformation,
                    parentBookingId: selectedSlot?.parentBookingId,
                };

                setBookingModel(bm); // in mem
                setContinueToConfirm(true); // for rerender
                setRedirectTo(`/booking/review`); // for after login
            }
        } catch (err) {
            // throw new Error(err);
        }
        // store info on local storage
    };

    useEffect(() => {
        const months = new Array<IMonthItem>();
        let i = 0;
        while (i < 12) {
            let date = moment().add(i, "month").startOf("month");
            months.push({
                dateString: date.format("YYYY-MM-DD"),
                name: date.format("MMMM YYYY"),
            });
            i++;
        }
        setMonths(months);

        // console.log("selecting ", months[0].dateString);
        // handleMonthSelect(months[0].dateString);
    }, []);

    //return bookingModel ? <Redirect to={`/booking/review`} /> : (
    return continueToConfirm ? (
        <Redirect to="booking/review" />
    ) : (
        <>
            <div className="o-calendar">
                <>
                    {/* TODO Need to show a loading animation to show when dates are being gathered  */}
                    <Row justify="space-between" className="mb-l">
                        <h3>1 of 4 - Select date</h3>
                    </Row>
                    <Row justify="space-between" className="mb-l">
                        <p>
                            Please select the date that you wish to book. Dates
                            are only clickable if there is availability. Once
                            you've selected the date, you can then choose the
                            trip type and start time.
                        </p>
                    </Row>

                    <Row className="mb-m ml-l">
                        <Col className="month-select" style={{ width: "30vh" }}>
                            {months && (
                                <Select
                                    style={{ width: "100%" }}
                                    defaultValue={moment(date).format(
                                        "MMMM YYYY",
                                    )}
                                    onChange={(value: any, option: any) => {
                                        if (value) {
                                            handleMonthSelect(value);
                                        }
                                    }}
                                    placeholder={`Select a month`}
                                >
                                    {months.map((month, index) => {
                                        return (
                                            <Select.Option
                                                key={month.dateString}
                                                value={month.dateString}
                                            >
                                                {month.name}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            )}
                        </Col>
                    </Row>

                    <Spin
                        spinning={!daysOfWeek}
                        style={{
                            width: "100%",
                        }}
                    >
                        {daysOfWeek && (
                            <Row justify="center" className="mb-l">
                                <Col
                                    span={24}
                                    className="previousVertical"
                                    onClick={handlePrevWeekClick}
                                >
                                    <span
                                        style={{
                                            display: "flex",
                                            alignItems: "flex-end",
                                        }}
                                        className="a-interactive-link"
                                    >
                                        {"<"}
                                    </span>
                                </Col>

                                <Col
                                    span={1}
                                    className="previous"
                                    onClick={handlePrevWeekClick}
                                >
                                    <span className="a-interactive-link">
                                        {"<"}
                                    </span>
                                </Col>

                                <Col span={22}>
                                    <Row
                                        justify="space-between"
                                        className="days"
                                    >
                                        <Day day={0} />
                                        <Day day={1} />
                                        <Day day={2} />
                                        <Day day={3} />
                                        <Day day={4} />
                                        <Day day={5} />
                                        <Day day={6} />
                                    </Row>
                                </Col>

                                <Col
                                    span={1}
                                    className="next"
                                    onClick={handleNextWeekClick}
                                >
                                    <span className="a-interactive-link">
                                        {">"}
                                    </span>
                                </Col>

                                <Col
                                    span={24}
                                    className="nextVertical"
                                    onClick={handleNextWeekClick}
                                >
                                    <span
                                        style={{
                                            display: "flex",
                                            alignItems: "flex-end",
                                        }}
                                        className="a-interactive-link"
                                    >
                                        {">"}
                                    </span>
                                </Col>
                            </Row>
                        )}
                    </Spin>
                </>

                {daySelected && (
                    <>
                        <ActivitySelect />
                    </>
                )}

                {activitySelected && (
                    <>
                        <SlotSelect />
                    </>
                )}

                {startTimeSelected && endTimeSelected && slotsSelected && (
                    //  - Any additional notes
                    <>
                        <Row
                            gutter={[16, 16]}
                            justify="space-between"
                            className="mb-l"
                        >
                            <h3>4 of 4 - Party information</h3>

                            {selectedSlot?.hasSlotAvailable && (
                                <Alert
                                    style={{
                                        width: "100%",
                                        textAlign: "center",
                                    }}
                                    message={
                                        <>
                                            <p>
                                                There is no charge for this
                                                trip, however donations are
                                                always welcome.
                                            </p>
                                        </>
                                    }
                                ></Alert>
                            )}
                        </Row>

                        <Form
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                            layout="horizontal"
                            form={form}
                            initialValues={{
                                additionalInformation: "",
                            }}
                        >
                            {/* <Form.Item
                                hasFeedback
                                name="numberPassengers2"
                                label="Total no. of passengers"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please select number of passengers",
                                    },
                                ]}
                            // validateStatus="error"
                            // help="Please select number of passengers"
                            >
                                <Button>-</Button>
                                <InputNumber></InputNumber>
                                <Button>+</Button>
                            </Form.Item> */}

                            <Form.Item
                                hasFeedback
                                name="numberPassengers"
                                label="Total no. of passengers"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please select number of passengers",
                                    },
                                ]}
                            >
                                <Select
                                    onChange={(value: number) =>
                                        setNumberOfPassengers(value)
                                    }
                                    value={numberOfPassengers}
                                    placeholder="Select number of passengers"
                                    size="large"
                                    listHeight={400}
                                >
                                    {(selectedSlot?.available ?? false) &&
                                    (selectedSlot?.hasSlotAvailable ?? false)
                                        ? Array.from(
                                              {
                                                  length: selectedSlot?.availableTotalSpaces,
                                              },
                                              (_, i) => (
                                                  <Select.Option
                                                      key={i + 1}
                                                      value={i + 1}
                                                  >
                                                      {i + 1}
                                                  </Select.Option>
                                              ),
                                          )
                                        : Array.from(
                                              {
                                                  length: 11,
                                              },
                                              (_, i) => (
                                                  <Select.Option
                                                      key={i}
                                                      value={i}
                                                  >
                                                      {i}
                                                  </Select.Option>
                                              ),
                                          )}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                hasFeedback
                                name="numberWheelchairs"
                                label="No. of wheelchairs"
                                initialValue={0}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please select number of wheelchairs",
                                    },
                                ]}
                            >
                                <Select
                                    onChange={(value: number) =>
                                        setNumberOfWheelChairPassengers(value)
                                    }
                                    defaultValue={numberOfWheelChairPassengers}
                                    placeholder="Select number of wheelchairs"
                                    size="large"
                                >
                                    {(selectedSlot?.available ?? false) &&
                                    (selectedSlot?.hasSlotAvailable ?? false)
                                        ? Array.from(
                                              {
                                                  length:
                                                      selectedSlot?.availableWheelchairSpaces +
                                                          1 || 0,
                                              },
                                              (_, i) => (
                                                  <Select.Option
                                                      key={i}
                                                      value={i}
                                                  >
                                                      {i}
                                                  </Select.Option>
                                              ),
                                          )
                                        : Array.from(
                                              {
                                                  length: 4,
                                              },
                                              (_, i) => (
                                                  <Select.Option
                                                      key={i}
                                                      value={i}
                                                  >
                                                      {i}
                                                  </Select.Option>
                                              ),
                                          )}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                hasFeedback
                                name="numberOfNonWheelchairDisabled"
                                label="No. of special needs passengers"
                                initialValue={0}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please select number of special needs passengers",
                                    },
                                ]}
                            >
                                <Select
                                    onChange={(value: number) =>
                                        setNumberOfNonWheelchairDisabledPassengers(
                                            value,
                                        )
                                    }
                                    defaultValue={
                                        numberOfNonWheelchairDisabledPassengers
                                    }
                                    size="large"
                                    placeholder="Select number of special needs passengers"
                                >
                                    {(selectedSlot?.available ?? false) &&
                                    (selectedSlot?.hasSlotAvailable ?? false)
                                        ? Array.from(
                                              {
                                                  length:
                                                      selectedSlot?.availableDisabledSpaces +
                                                          1 || 0,
                                              },
                                              (_, i) => (
                                                  <Select.Option
                                                      key={i}
                                                      value={i}
                                                  >
                                                      {i}
                                                  </Select.Option>
                                              ),
                                          )
                                        : Array.from(
                                              {
                                                  length: 11,
                                              },
                                              (_, i) => (
                                                  <Select.Option
                                                      key={i}
                                                      value={i}
                                                  >
                                                      {i}
                                                  </Select.Option>
                                              ),
                                          )}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="additionalInformation"
                                label="Additional information"
                                initialValue={0}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <TextArea
                                    rows={4}
                                    value={additionalInformation}
                                    onChange={(e) =>
                                        setAdditionalInformation(e.target.value)
                                    }
                                />
                            </Form.Item>

                            {daySelected &&
                                startTimeSelected &&
                                endTimeSelected &&
                                slotSize && (
                                    <Row justify="center" className="mt-m">
                                        <Col>
                                            <Button
                                                onClick={handleBookingFormClick}
                                                htmlType="button"
                                                type="primary"
                                                size="large"
                                            >
                                                Proceed with booking {">"}
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                        </Form>
                    </>
                )}
            </div>
        </>
    );
}

export default Calendar;

export interface IMonthItem {
    name: string;
    dateString: string;
}
