enum Report {
    Confirmed,
    Cancelled,
    Unconfirmed,
    All,
    None,
    Complete,
}

export default Report;
