import React from "react";
import { Col } from "antd";
import { useCalendar } from "./CalendarContext";
import moment from "moment";
import IAvailableSlotModel from "../../interfaces/IAvailableSlotModel";

function Time({ slot, index }: ITimeProps) {
    const { handleTimeClick, handleTimeHover, handleTimeHoverOut } =
        useCalendar();

    const { available, startTime, endTime } = slot;

    return (
        <>
            <Col
                md={6}
                xs={12}
                className={`p-m m-time ${available ? "" : "disabled"}`}
            >
                <p
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    }}
                    className={`p-xxs`}
                    data-slot-json={JSON.stringify(slot)}
                    data-time-start={startTime}
                    data-time-end={endTime}
                    data-index={index}
                    onClick={handleTimeClick}
                    onMouseEnter={handleTimeHover}
                    onMouseLeave={handleTimeHoverOut}
                >
                    {moment(startTime).format("h:mm a")}

                    {slot.hasSlotAvailable && (
                        <>
                            <span style={{ display: "block", fontSize: "80%" }}>
                                Limited availability
                            </span>
                        </>
                    )}
                </p>
            </Col>
        </>
    );
}

export default Time;

interface ITimeProps {
    index: number;
    slot: IAvailableSlotModel;
}
