import React, { useLayoutEffect } from "react";
import { Layout, Menu } from "antd";
import {
    UserOutlined,
    BookOutlined,
    CalendarOutlined,
    LogoutOutlined,
    UsergroupAddOutlined,
    MailOutlined,
    DotChartOutlined,
    FieldTimeOutlined,
    UserSwitchOutlined,
} from "@ant-design/icons";
import { NavLink, useLocation } from "react-router-dom";
import { useApp } from "../../AppContext";
import User from "../../enums/User";

const { Sider, Content } = Layout;

const menuSelected = ["bookings", "skippers", "passengers", "clients"];

function DashboardLayout({ children }: IDashboardLayout) {
    // const [collapsed, setCollapsed] = useState<boolean>(false);
    const { userType, setUserType, setMenuKey, setJwtToken } = useApp();

    const location = useLocation();
    let menuItem: string = null;

    useLayoutEffect(() => {
        const locationPathSplit = location.pathname.split("/");
        if (locationPathSplit.length > 2) {
            menuItem = locationPathSplit[2].toLowerCase();
            for (let i: number = 0; i < menuSelected.length; i++) {
                if (menuSelected[i] === menuItem) {
                    setMenuKey(i);
                }
            }
        }
    }, [location, setMenuKey]);

    return (
        <>
            <Layout
                className="m-dashboard-navigation"
                style={{ minHeight: "100vh" }}
            >
                <Sider
                    breakpoint="md"
                    collapsedWidth="0"
                    style={{ backgroundColor: "white" }}
                >
                    <div className="logo">
                        <a href="/">
                            <img
                                src="../images/logo-jersey.png"
                                alt="wetwheels"
                            />
                        </a>
                    </div>
                    <Menu
                        theme="light"
                        mode="vertical"
                        selectedKeys={[menuItem]}
                    >
                        <Menu.Item key="bookings" icon={<BookOutlined />}>
                            <NavLink to="/dashboard/bookings">Bookings</NavLink>
                        </Menu.Item>
                        {userType === User.Skipper && (
                            <Menu.Item
                                key="availability"
                                icon={<CalendarOutlined />}
                            >
                                <NavLink to={`/dashboard/availability`}>
                                    Availability
                                </NavLink>
                            </Menu.Item>
                        )}

                        <Menu.Item key="account" icon={<UserOutlined />}>
                            <NavLink to="/dashboard/account">Account</NavLink>
                        </Menu.Item>

                        {userType === User.Admin && (
                            <Menu.Item
                                key="skippers"
                                icon={<UsergroupAddOutlined />}
                            >
                                <NavLink to="/dashboard/skippers/">
                                    Skippers
                                </NavLink>
                            </Menu.Item>
                        )}
                        {userType === User.Admin && (
                            <Menu.Item
                                key="crew"
                                icon={<UsergroupAddOutlined />}
                            >
                                <NavLink to="/dashboard/crew">Crew</NavLink>
                            </Menu.Item>
                        )}
                        {userType === User.Admin && (
                            <Menu.Item key="clients" icon={<UserOutlined />}>
                                <NavLink to="/dashboard/clients">
                                    Clients
                                </NavLink>
                            </Menu.Item>
                        )}
                        {userType === User.Admin && (
                            <Menu.Item
                                key="admins"
                                icon={<UserSwitchOutlined />}
                            >
                                <NavLink to="/dashboard/admins">Admins</NavLink>
                            </Menu.Item>
                        )}
                        {userType === User.Admin && (
                            <Menu.Item
                                key="events"
                                icon={<FieldTimeOutlined />}
                            >
                                <NavLink to="/dashboard/eventtypes">
                                    Event types
                                </NavLink>
                            </Menu.Item>
                        )}
                        {userType === User.Admin && (
                            <Menu.Item key="email" icon={<MailOutlined />}>
                                <NavLink to="/dashboard/emailtemplates">
                                    Email templates
                                </NavLink>
                            </Menu.Item>
                        )}
                        {/* {userType === User.Admin && (
              <Menu.Item key="emailqueue" icon={<MailOutlined />}>
                <NavLink to="/dashboard/emailqueue">Queued email</NavLink>
              </Menu.Item>
            )} */}
                        {userType === User.Admin && (
                            <Menu.Item
                                key="reports"
                                icon={<DotChartOutlined />}
                            >
                                <NavLink to="/dashboard/reports">
                                    Reports
                                </NavLink>
                            </Menu.Item>
                        )}
                        <Menu.Divider />
                        <Menu.Item
                            key="4"
                            icon={<LogoutOutlined />}
                            onClick={() => {
                                setUserType(User.None);
                                setJwtToken(null);
                            }}
                        >
                            Logout
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Layout
                    className="site-layout"
                    style={{
                        marginBottom: "50px",
                    }}
                >
                    <Content className="site-layout-background">
                        {children}
                    </Content>
                </Layout>
            </Layout>
        </>
    );
}

export default DashboardLayout;

interface IDashboardLayout {
    children: React.ReactNode;
}
