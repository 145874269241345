import React from "react";
import { Col } from "antd";
import { useCalendar } from "./CalendarContext";
import moment from "moment";

function Day({ day }: IDayProps) {
    const { handleDayClick, daysOfWeek } = useCalendar();
    const dateString = moment(daysOfWeek[day].date);

    return (
        <>
            <Col
                md={3}
                sm={24}
                xs={24}
                className={`m-day ${
                    daysOfWeek[day].available ? "active" : "disabled"
                }`}
            >
                <p
                    className="p-xs match-height"
                    data-date={dateString}
                    onClick={
                        daysOfWeek[day].available ? handleDayClick : undefined
                    }
                >
                    {dateString.format("ddd, MMM Do")}
                </p>
            </Col>
        </>
    );
}

export default Day;

interface IDayProps {
    day: number;
}
