import React, { useState, useEffect } from "react";
import moment from "moment";
import { notification } from "antd";

import * as WetwheelsApi from "../../client-swagger/api";
import IAvailableSlotModel from "../../interfaces/IAvailableSlotModel";

const CalendarContext = React.createContext<ICalendarContext>(undefined);

const CalendarProvider = ({ children }: ICalendarProviderProps) => {
    const [date, setDate] = useState<Date>(new Date());
    const [daysOfWeek, setDaysOfWeek] = useState();
    const [daySelected, setDaySelected] = useState<moment.Moment | null>(null);
    const [startTimeSelected, setStartTimeSelected] = useState<string>(null);
    const [slotsSelected, setSlotsSelected] = useState(false);
    const [endTimeSelected, setEndTimeSelected] = useState<string>(null);
    const [activities, setActivities] = useState<any[]>();
    const [activitySelected, setActivitySelected] = useState<string>("");
    const [activityNameSelected, setActivityNameSelected] =
        useState<string>("");
    const [slotSize, setSlotSize] = useState<number>(0);
    const [slots, setSlots] = useState<IAvailableSlotModel[]>();
    const [selectedSlot, setSelectedSlot] =
        useState<IAvailableSlotModel | null>(null);

    const getData = async () => {
        if (!date) {
            return;
        }
        console.log("getting data");
        await new WetwheelsApi.BookingApi()
            .bookingListAvailableDatesByWeekGet(date.toJSON())
            .then(({ value }: any) => {
                // setDaysOfWeek(data.value);
                setDaysOfWeek(value.data);
            });
    };

    // get the slot availabality by day
    useEffect(() => {
        getData();
    }, [date]);

    const openNotificationWithIcon = () => {
        notification.error({
            message: "Booking cannot be made",
            description: `This activity requires ${slotSize} slots, please make sure you select a time which is available for all slots`,
        });
    };

    const resetTime = () => {
        // unselect all others
        const times = document.querySelectorAll(".m-time");
        times.forEach((time) => {
            if (time?.classList.contains("selected")) {
                time.classList.remove("selected");
            }
        });
        setSelectedSlot(null);
        setStartTimeSelected("");
        setEndTimeSelected("");
    };

    const resetDate = () => {
        // unselect all others
        const days = document.querySelectorAll(".m-day");
        days.forEach((day) => {
            if (day.classList.contains("selected")) {
                day.classList.remove("selected");
            }
        });
        setDaySelected(null);
    };

    const resetActivity = () => {
        setActivities(null);
        // const activity: HTMLSelectElement = document.querySelector(
        //   ".m-activity"
        // ) as HTMLSelectElement;

        // if (!activity) return;

        setActivityNameSelected(null);
        setActivitySelected(null);
        setSlotSize(0);
    };

    const handleDayClick = (e: React.MouseEvent<HTMLElement>) => {
        if (!e) return;

        console.log("handleDayClick");
        resetDate();
        resetTime();
        resetActivity();
        setStartTimeSelected(null);
        setEndTimeSelected(null);

        // get the date from data-date
        const date = new Date(Number(e.currentTarget.dataset?.date));
        console.log("date", date);
        console.log("moment(date)", moment(date));
        setDaySelected(moment(date));
        e.currentTarget.parentElement?.classList.add("selected");
    };

    const handlePrevWeekClick = (e: React.MouseEvent<HTMLElement>) => {
        if (!date) return;

        setDaysOfWeek(null);
        setDaySelected(null);
        resetDate();
        setStartTimeSelected("");
        setEndTimeSelected("");
        resetTime();

        const newDate = new Date(date.setDate(date.getDate() - 7));
        setDate(newDate);
    };

    const handleNextWeekClick = (e: React.MouseEvent<HTMLElement>) => {
        if (!date) return;
        //e.currentTarget.classList.add("loading");
        setDaysOfWeek(null);
        setDaySelected(null);
        resetDate();
        setStartTimeSelected("");
        setEndTimeSelected("");
        resetTime();

        const newDate = new Date(date.setDate(date.getDate() + 7));
        setDate(newDate);
        //e.currentTarget.classList.remove("loading");
    };

    const handleMonthSelect = (startDate: string) => {
        console.log("🚀 ----------------------------------------------🚀");
        console.log("🚀 ~ handleMonthSelect ~ startDate:", startDate, date);
        console.log("🚀 ----------------------------------------------🚀");
        if (!date) return;
        //e.currentTarget.classList.add("loading");
        setDaysOfWeek(null);
        setDaySelected(null);
        resetDate();
        setStartTimeSelected("");
        setEndTimeSelected("");
        resetTime();
        const newDate = moment(startDate).startOf("week").toDate();
        console.log("🚀 ------------------------------------------🚀");
        console.log("🚀 ~ handleMonthSelect ~ newDate:", newDate);
        console.log("🚀 ------------------------------------------🚀");
        setDate(newDate);
    };

    // -----------------------------------------------------------
    //                        Time
    // -----------------------------------------------------------
    const handleTimeClick = (e: React.MouseEvent<HTMLElement>) => {
        if (!e) return;

        // unselect all others
        resetTime();

        // get time from data-time
        const ele = e.currentTarget.parentElement;
        const startTime: string = e.currentTarget.dataset?.timeStart;
        const endTime: string = e.currentTarget.dataset?.timeEnd;
        const startKey = e.currentTarget.dataset?.index;
        const slotJson = e.currentTarget.dataset?.slotJson;

        setSelectedSlot(JSON.parse(slotJson));

        let additionalElement: HTMLElement;

        if (slotSize && startKey && slots) {
            // if last then it cannot be done

            for (let i = 0; i < slotSize; i++) {
                // just get the next one
                additionalElement = document.querySelector(
                    `.m-time p[data-index="${Number(startKey) + i}"]`,
                );
                // if the selection is taken, it cannot be done
                if (
                    additionalElement?.parentElement?.classList.contains(
                        "disabled",
                    )
                ) {
                    ele?.classList.add("failure");
                    setTimeout(() => {
                        ele?.classList.remove("failure");
                    }, 2000);
                    return;
                }

                additionalElement?.parentElement?.classList.add("selected");
            }

            if (Number(startKey) + slotSize > slots.length) {
                ele?.classList.add("failure");
                openNotificationWithIcon();
                setTimeout(() => {
                    ele?.classList.remove("failure");
                }, 2000);
                return;
            }
        }

        // start time will always be slot selected start time
        setStartTimeSelected(startTime);

        switch (slotSize) {
            case 1:
                setEndTimeSelected(endTime);
                break;
            case 2:
            case 3:
                setEndTimeSelected(additionalElement.dataset?.timeEnd);
                break;
            case 4:
                setEndTimeSelected(additionalElement.dataset?.timeEnd);
                break;
        }

        setSlotsSelected(true);

        e.currentTarget.parentElement?.classList.add("selected");
    };

    const handleTimeHover = (e: React.MouseEvent<HTMLElement>) => {
        if (!e) return;

        const startKey = e.currentTarget.dataset?.index;

        const addAdditionalSlots = () => {
            if (slotSize && startKey) {
                // just get the next one
                for (let i = 0; i < slotSize; i++) {
                    const additionalElement = document.querySelector(
                        `.m-time p[data-index="${Number(startKey) + i}"]`,
                    );
                    additionalElement?.classList.add("active");
                }
            }
        };

        addAdditionalSlots();
    };

    const handleTimeHoverOut = () => {
        const elements = document.querySelectorAll(".m-time p");
        elements.forEach((element) => {
            element.classList.remove("active");
        });
    };

    const state: ICalendarContext = {
        date,
        setDate,
        daysOfWeek,
        setDaysOfWeek,
        daySelected,
        setDaySelected,
        startTimeSelected,
        setStartTimeSelected,
        endTimeSelected,
        setEndTimeSelected,
        activities,
        setActivities,
        activitySelected,
        setActivitySelected,
        activityNameSelected,
        setActivityNameSelected,
        slots,
        setSlots,
        slotsSelected,
        setSlotsSelected,
        slotSize,
        setSlotSize,
        handleTimeClick,
        handleDayClick,
        handlePrevWeekClick,
        handleNextWeekClick,
        handleTimeHover,
        handleTimeHoverOut,
        handleMonthSelect,
        resetTime,
        setSelectedSlot,
        selectedSlot,
    };

    return (
        <CalendarContext.Provider value={state}>
            {children}
        </CalendarContext.Provider>
    );
};

function useCalendar() {
    const context = React.useContext(CalendarContext);

    if (context === undefined) {
        throw new Error("useCalendar must be used within a CalendarProvider");
    }

    return context;
}

export { CalendarProvider, useCalendar };

interface ICalendarProviderProps {
    children: React.ReactNode;
}

type ICalendarContext = {
    date: Date;
    setDate: (value: Date) => void;
    daysOfWeek: any[];
    setDaysOfWeek: (value: any) => void;
    daySelected: moment.Moment | null;
    setDaySelected: (value: moment.Moment) => void;
    startTimeSelected: string;
    setStartTimeSelected: (value: string) => void;
    endTimeSelected: string;
    setEndTimeSelected: (value: string) => void;
    activities: any[];
    setActivities: (value: any[]) => void;
    activitySelected: string;
    setActivitySelected: (value: string) => void;
    activityNameSelected: string;
    setActivityNameSelected: (value: string) => void;
    slotsSelected: boolean;
    setSlotsSelected: (value: boolean) => void;
    slotSize: number;
    setSlotSize: (value: number) => void;
    slots: IAvailableSlotModel[];
    setSlots: (value: IAvailableSlotModel[]) => void;
    handleTimeClick: (e: React.MouseEvent<HTMLElement>) => void;
    handleDayClick: (e: React.MouseEvent<HTMLElement>) => void;
    handlePrevWeekClick: (e: React.MouseEvent<HTMLElement>) => void;
    handleNextWeekClick: (e: React.MouseEvent<HTMLElement>) => void;
    handleTimeHover: (e: React.MouseEvent<HTMLElement>) => void;
    handleTimeHoverOut: () => void;
    handleMonthSelect: (startDate: string) => void;
    resetTime: () => void;
    setSelectedSlot: (value: IAvailableSlotModel | null) => void;
    selectedSlot: IAvailableSlotModel | null;
};
