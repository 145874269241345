/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Wetwheels
 * API end points for Wetwheels
 *
 * OpenAPI spec version: 1.3.1
 * Contact: tom@webreality.co.uk
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as url from "url";
import * as portableFetch from "portable-fetch";
import isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = process.env.REACT_APP_BASE_URL.replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(
        configuration?: Configuration,
        protected basePath: string = BASE_PATH,
        protected fetch: FetchAPI = portableFetch,
    ) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 *
 * @export
 * @interface ActivateRequest
 */
export interface ActivateRequest {
    /**
     *
     * @type {string}
     * @memberof ActivateRequest
     */
    userId?: string;
}
/**
 *
 * @export
 * @interface ApiResponse
 */
export interface ApiResponse {
    /**
     *
     * @type {boolean}
     * @memberof ApiResponse
     */
    success?: boolean;
    /**
     *
     * @type {number}
     * @memberof ApiResponse
     */
    code?: number;
    /**
     *
     * @type {string}
     * @memberof ApiResponse
     */
    message?: string;
    /**
     *
     * @type {any}
     * @memberof ApiResponse
     */
    data?: any;
}
/**
 *
 * @export
 * @interface BookingDisclaimerModel
 */
export interface BookingDisclaimerModel {
    /**
     *
     * @type {string}
     * @memberof BookingDisclaimerModel
     */
    bookingId?: string;
    /**
     *
     * @type {string}
     * @memberof BookingDisclaimerModel
     */
    signatureBase64String?: string;
    /**
     *
     * @type {string}
     * @memberof BookingDisclaimerModel
     */
    signedBy?: string;
    /**
     *
     * @type {string}
     * @memberof BookingDisclaimerModel
     */
    disclaimertext?: string;
}
/**
 *
 * @export
 * @interface CancelBooking
 */
export interface CancelBooking {
    /**
     *
     * @type {string}
     * @memberof CancelBooking
     */
    bookingId?: string;
}
/**
 *
 * @export
 * @interface ChildBookingDetails
 */
export interface ChildBookingDetails {
    /**
     *
     * @type {boolean}
     * @memberof ChildBookingDetails
     */
    sendEmails?: boolean;
    /**
     *
     * @type {number}
     * @memberof ChildBookingDetails
     */
    numberOfPassengers: number;
    /**
     *
     * @type {number}
     * @memberof ChildBookingDetails
     */
    numberOfWheelchairs: number;
    /**
     *
     * @type {number}
     * @memberof ChildBookingDetails
     */
    numberOfDisabledNonWheelchair: number;
    /**
     *
     * @type {string}
     * @memberof ChildBookingDetails
     */
    emailAddress: string;
    /**
     *
     * @type {string}
     * @memberof ChildBookingDetails
     */
    firstName?: string;
    /**
     *
     * @type {string}
     * @memberof ChildBookingDetails
     */
    lastName?: string;
    /**
     *
     * @type {string}
     * @memberof ChildBookingDetails
     */
    organisation?: string;
    /**
     *
     * @type {string}
     * @memberof ChildBookingDetails
     */
    phoneNumber?: string;
}
/**
 *
 * @export
 * @interface CreateBooking
 */
export interface CreateBooking {
    /**
     *
     * @type {string}
     * @memberof CreateBooking
     */
    userId?: string;
    /**
     *
     * @type {string}
     * @memberof CreateBooking
     */
    eventTypeId?: string;
    /**
     *
     * @type {string}
     * @memberof CreateBooking
     */
    startDate?: string;
    /**
     *
     * @type {string}
     * @memberof CreateBooking
     */
    endDate?: string;
    /**
     *
     * @type {number}
     * @memberof CreateBooking
     */
    numberOfPassengers?: number;
    /**
     *
     * @type {number}
     * @memberof CreateBooking
     */
    numberOfWheelchairs?: number;
    /**
     *
     * @type {number}
     * @memberof CreateBooking
     */
    numberOfNonWheelchairDisabled?: number;
    /**
     *
     * @type {string}
     * @memberof CreateBooking
     */
    additionalInformation?: string;
    /**
     *
     * @type {string}
     * @memberof CreateBooking
     */
    parentBookingId?: string;
}
/**
 *
 * @export
 * @interface CreateCrew
 */
export interface CreateCrew {
    /**
     *
     * @type {string}
     * @memberof CreateCrew
     */
    firstName?: string;
    /**
     *
     * @type {string}
     * @memberof CreateCrew
     */
    lastName?: string;
    /**
     *
     * @type {string}
     * @memberof CreateCrew
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof CreateCrew
     */
    contactNumber?: string;
    /**
     *
     * @type {string}
     * @memberof CreateCrew
     */
    password?: string;
    /**
     *
     * @type {string}
     * @memberof CreateCrew
     */
    confirmPassword?: string;
}
/**
 *
 * @export
 * @interface CreateSkipper
 */
export interface CreateSkipper {
    /**
     *
     * @type {string}
     * @memberof CreateSkipper
     */
    firstName?: string;
    /**
     *
     * @type {string}
     * @memberof CreateSkipper
     */
    lastName?: string;
    /**
     *
     * @type {string}
     * @memberof CreateSkipper
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof CreateSkipper
     */
    contactNumber?: string;
    /**
     *
     * @type {string}
     * @memberof CreateSkipper
     */
    password?: string;
    /**
     *
     * @type {string}
     * @memberof CreateSkipper
     */
    confirmPassword?: string;
    /**
     *
     * @type {CreateSkipperAvailabilty}
     * @memberof CreateSkipper
     */
    availabilty?: CreateSkipperAvailabilty;
}
/**
 *
 * @export
 * @interface CreateSkipperAvailabilty
 */
export interface CreateSkipperAvailabilty {
    /**
     *
     * @type {string}
     * @memberof CreateSkipperAvailabilty
     */
    isAvailableMonday?: string;
    /**
     *
     * @type {string}
     * @memberof CreateSkipperAvailabilty
     */
    isAvailableTuesday?: string;
    /**
     *
     * @type {string}
     * @memberof CreateSkipperAvailabilty
     */
    isAvailableWednesday?: string;
    /**
     *
     * @type {string}
     * @memberof CreateSkipperAvailabilty
     */
    isAvailableThursday?: string;
    /**
     *
     * @type {string}
     * @memberof CreateSkipperAvailabilty
     */
    isAvailableFriday?: string;
    /**
     *
     * @type {string}
     * @memberof CreateSkipperAvailabilty
     */
    isAvailableSaturday?: string;
    /**
     *
     * @type {string}
     * @memberof CreateSkipperAvailabilty
     */
    isAvailableSunday?: string;
}
/**
 *
 * @export
 * @interface CreateUser
 */
export interface CreateUser {
    /**
     *
     * @type {string}
     * @memberof CreateUser
     */
    firstName?: string;
    /**
     *
     * @type {string}
     * @memberof CreateUser
     */
    lastName?: string;
    /**
     *
     * @type {string}
     * @memberof CreateUser
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof CreateUser
     */
    organisation?: string;
    /**
     *
     * @type {string}
     * @memberof CreateUser
     */
    password?: string;
    /**
     *
     * @type {string}
     * @memberof CreateUser
     */
    contactNumber?: string;
    /**
     *
     * @type {string}
     * @memberof CreateUser
     */
    confirmPassword?: string;
    /**
     *
     * @type {string}
     * @memberof CreateUser
     */
    role?: string;

    googleReCaptchaResponse: string;
}
/**
 *
 * @export
 * @interface CrewAuthBooking
 */
export interface CrewAuthBooking {
    /**
     *
     * @type {string}
     * @memberof CrewAuthBooking
     */
    crewId?: string;
    /**
     *
     * @type {string}
     * @memberof CrewAuthBooking
     */
    bookingId?: string;
    /**
     *
     * @type {string}
     * @memberof CrewAuthBooking
     */
    status?: string;
}
/**
 *
 * @export
 * @interface EmailEnque
 */
export interface EmailEnque {
    /**
     *
     * @type {string}
     * @memberof EmailEnque
     */
    bookingId?: string;
    /**
     *
     * @type {string}
     * @memberof EmailEnque
     */
    templateId?: string;
}
/**
 *
 * @export
 * @interface EmailTemplate
 */
export interface EmailTemplate {
    /**
     *
     * @type {string}
     * @memberof EmailTemplate
     */
    key?: string;
    /**
     *
     * @type {string}
     * @memberof EmailTemplate
     */
    friendlyName?: string;
    /**
     *
     * @type {string}
     * @memberof EmailTemplate
     */
    sendFromName?: string;
    /**
     *
     * @type {string}
     * @memberof EmailTemplate
     */
    sendFromEmail?: string;
    /**
     *
     * @type {boolean}
     * @memberof EmailTemplate
     */
    shouldSendToClient?: boolean;
    /**
     *
     * @type {string}
     * @memberof EmailTemplate
     */
    sendToEmail?: string;
    /**
     *
     * @type {string}
     * @memberof EmailTemplate
     */
    subject?: string;
    /**
     *
     * @type {string}
     * @memberof EmailTemplate
     */
    body?: string;
    /**
     *
     * @type {string}
     * @memberof EmailTemplate
     */
    bccEmail?: string;
}
/**
 *
 * @export
 * @interface EventType
 */
export interface EventType {
    /**
     *
     * @type {string}
     * @memberof EventType
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof EventType
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EventType
     */
    description: string;
    /**
     *
     * @type {number}
     * @memberof EventType
     */
    noOfSlots: number;
}
/**
 *
 * @export
 * @interface Login
 */
export interface Login {
    /**
     *
     * @type {string}
     * @memberof Login
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof Login
     */
    password?: string;
}
/**
 *
 * @export
 * @interface Manifest
 */
export interface Manifest {
    /**
     *
     * @type {string}
     * @memberof Manifest
     */
    bookingId?: string;
    /**
     *
     * @type {string}
     * @memberof Manifest
     */
    userId?: string;
    /**
     *
     * @type {Array<ManifestPassengers>}
     * @memberof Manifest
     */
    passengers?: Array<ManifestPassengers>;
}
/**
 *
 * @export
 * @interface ManifestPassengers
 */
export interface ManifestPassengers {
    /**
     *
     * @type {string}
     * @memberof ManifestPassengers
     */
    firstName?: string;
    /**
     *
     * @type {string}
     * @memberof ManifestPassengers
     */
    lastName?: string;
    /**
     *
     * @type {boolean}
     * @memberof ManifestPassengers
     */
    isWheelChairUser?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ManifestPassengers
     */
    isDisabledNonWheelChair?: boolean;
    /**
     *
     * @type {string}
     * @memberof ManifestPassengers
     */
    ageRangeDescription?: string;
    /**
     *
     * @type {string}
     * @memberof ManifestPassengers
     */
    passengerAgeRangeId?: string;
    /**
     *
     * @type {string}
     * @memberof ManifestPassengers
     */
    gender?: ManifestPassengers.GenderEnum;
    /**
     *
     * @type {boolean}
     * @memberof ManifestPassengers
     */
    hasGivenPhotoConsent?: boolean;
}

/**
 * @export
 * @namespace ManifestPassengers
 */
export declare namespace ManifestPassengers {
    /**
     * @export
     * @enum {string}
     */
    export enum GenderEnum {
        Male = "Male",
        Female = "Female",
        Unspecified = "Unspecified",
    }
}
/**
 *
 * @export
 * @interface PassengerManifest
 */
export interface PassengerManifest {
    /**
     *
     * @type {string}
     * @memberof PassengerManifest
     */
    manifestId?: string;
    /**
     *
     * @type {string}
     * @memberof PassengerManifest
     */
    firstName?: string;
    /**
     *
     * @type {string}
     * @memberof PassengerManifest
     */
    lastName?: string;
    /**
     *
     * @type {boolean}
     * @memberof PassengerManifest
     */
    isWheelChairUser?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof PassengerManifest
     */
    isDisabledNonWheelChair?: boolean;
    /**
     *
     * @type {string}
     * @memberof PassengerManifest
     */
    passengerAgeRangeId?: string;
    /**
     *
     * @type {string}
     * @memberof PassengerManifest
     */
    gender?: PassengerManifest.GenderEnum;
    /**
     *
     * @type {boolean}
     * @memberof PassengerManifest
     */
    hasGivenPhotoConsent?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof PassengerManifest
     */
    isRepeatUser?: boolean;
}

/**
 * @export
 * @namespace PassengerManifest
 */
export declare namespace PassengerManifest {
    /**
     * @export
     * @enum {string}
     */
    export enum GenderEnum {
        Male = "Male",
        Female = "Female",
        Unspecified = "Unspecified",
    }
}
/**
 *
 * @export
 * @interface ReportQuery
 */
export interface ReportQuery {
    /**
     *
     * @type {string}
     * @memberof ReportQuery
     */
    timeFrame?: ReportQuery.TimeFrameEnum;
    /**
     *
     * @type {string}
     * @memberof ReportQuery
     */
    bookingStatus?: ReportQuery.BookingStatusEnum;
}

/**
 * @export
 * @namespace ReportQuery
 */
export declare namespace ReportQuery {
    /**
     * @export
     * @enum {string}
     */
    export enum TimeFrameEnum {
        Past = "Past",
        Future = "Future",
    }
    /**
     * @export
     * @enum {string}
     */
    export enum BookingStatusEnum {
        All = "All",
        Confirmed = "Confirmed",
        Unconfirmed = "Unconfirmed",
        Cancelled = "Cancelled",
        Partial = "Partial",
        Complete = "Complete",
    }
}
/**
 *
 * @export
 * @interface ResetPassword
 */
export interface ResetPassword {
    /**
     *
     * @type {string}
     * @memberof ResetPassword
     */
    resetKey?: string;
    /**
     *
     * @type {string}
     * @memberof ResetPassword
     */
    password?: string;
    /**
     *
     * @type {string}
     * @memberof ResetPassword
     */
    passwordConfirm?: string;
}
/**
 *
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     *
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    email?: string;
}
/**
 *
 * @export
 * @interface SkipperAuthBooking
 */
export interface SkipperAuthBooking {
    /**
     *
     * @type {string}
     * @memberof SkipperAuthBooking
     */
    skipperId?: string;
    /**
     *
     * @type {string}
     * @memberof SkipperAuthBooking
     */
    bookingId?: string;
    /**
     *
     * @type {string}
     * @memberof SkipperAuthBooking
     */
    status?: string;
}
/**
 *
 * @export
 * @interface SkipperUnavailable
 */
export interface SkipperUnavailable {
    /**
     *
     * @type {string}
     * @memberof SkipperUnavailable
     */
    skipperId?: string;
    /**
     *
     * @type {string}
     * @memberof SkipperUnavailable
     */
    date?: string;
    /**
     *
     * @type {string}
     * @memberof SkipperUnavailable
     */
    status?: SkipperUnavailable.StatusEnum;
}

/**
 * @export
 * @namespace SkipperUnavailable
 */
export declare namespace SkipperUnavailable {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        AM = "AM",
        PM = "PM",
        BOTH = "BOTH",
    }
}
/**
 *
 * @export
 * @interface UpdateAccount
 */
export interface UpdateAccount {
    /**
     *
     * @type {string}
     * @memberof UpdateAccount
     */
    userId?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateAccount
     */
    firstName?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateAccount
     */
    lastName?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateAccount
     */
    contactNumber?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateAccount
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateAccount
     */
    oldPassword?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateAccount
     */
    newPassword?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateAccount
     */
    confirmNewPassword?: string;
}
/**
 *
 * @export
 * @interface UpdatePassengerManifest
 */
export interface UpdatePassengerManifest {
    /**
     *
     * @type {string}
     * @memberof UpdatePassengerManifest
     */
    firstName?: string;
    /**
     *
     * @type {string}
     * @memberof UpdatePassengerManifest
     */
    lastName?: string;
    /**
     *
     * @type {boolean}
     * @memberof UpdatePassengerManifest
     */
    isWheelChairUser?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof UpdatePassengerManifest
     */
    isDisabledNonWheelChair?: boolean;
    /**
     *
     * @type {string}
     * @memberof UpdatePassengerManifest
     */
    passengerAgeRangeId?: string;
    /**
     *
     * @type {string}
     * @memberof UpdatePassengerManifest
     */
    gender?: UpdatePassengerManifest.GenderEnum;
    /**
     *
     * @type {boolean}
     * @memberof UpdatePassengerManifest
     */
    hasGivenPhotoConsent?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof UpdatePassengerManifest
     */
    isRepeatUser?: boolean;
}

/**
 * @export
 * @namespace UpdatePassengerManifest
 */
export declare namespace UpdatePassengerManifest {
    /**
     * @export
     * @enum {string}
     */
    export enum GenderEnum {
        Male = "Male",
        Female = "Female",
        Unspecified = "Unspecified",
    }
}
/**
 * AccountApi - fetch parameter creator
 * @export
 */
export const AccountApiFetchParamCreator = function (
    configuration?: Configuration,
) {
    return {
        /**
         * Creates a new account
         * @summary Creates a new account
         * @param {CreateUser} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountSignup(body: CreateUser, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling accountSignup.",
                );
            }
            const localVarPath = `/account/signup`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"CreateUser" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Activates a new user account
         * @summary actviates a new user
         * @param {ActivateRequest} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateUser(body?: ActivateRequest, options: any = {}): FetchArgs {
            const localVarPath = `/account/activate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"ActivateRequest" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * authenticates the user
         * @summary authenticates the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUser(options: any = {}): FetchArgs {
            const localVarPath = `/account/authenticate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets the users account, including password
         * @summary gets the users account
         * @param {string} userId Unique Id for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(userId: string, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError(
                    "userId",
                    "Required parameter userId was null or undefined when calling getAccount.",
                );
            }
            const localVarPath = `/account`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (userId !== undefined) {
                localVarQueryParameter["userId"] = userId;
            }

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Login to account
         * @param {Login} body Checks the users credentials and returns a JWT if they log in successfully. Otherwise returns an error which is displayed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(body: Login, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling login.",
                );
            }
            const localVarPath = `/account/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"Login" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Checks the email provided and if exists sends an email prompting the user to follow a link to reset their password
         * @summary requests a password reset
         * @param {ResetPasswordRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestResetPassword(
            body: ResetPasswordRequest,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling requestResetPassword.",
                );
            }
            const localVarPath = `/account/requestResetPassword`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"ResetPasswordRequest" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Checks that the account exists by reset key, the key is still valid, and resets the password
         * @summary resets the users password, based on the reset key
         * @param {ResetPassword} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(body: ResetPassword, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling resetPassword.",
                );
            }
            const localVarPath = `/account/resetPassword`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"ResetPassword" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the users account, including password
         * @summary updates the users account
         * @param {UpdateAccount} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(body: UpdateAccount, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling updateAccount.",
                );
            }
            const localVarPath = `/account`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "PATCH" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"UpdateAccount" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new account
         * @summary Creates a new account
         * @param {CreateUser} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountSignup(
            body: CreateUser,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = AccountApiFetchParamCreator(
                configuration,
            ).accountSignup(body, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Activates a new user account
         * @summary actviates a new user
         * @param {ActivateRequest} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateUser(
            body?: ActivateRequest,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = AccountApiFetchParamCreator(
                configuration,
            ).activateUser(body, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * authenticates the user
         * @summary authenticates the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUser(
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs =
                AccountApiFetchParamCreator(configuration).authenticateUser(
                    options,
                );
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gets the users account, including password
         * @summary gets the users account
         * @param {string} userId Unique Id for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(
            userId: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = AccountApiFetchParamCreator(
                configuration,
            ).getAccount(userId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Login to account
         * @param {Login} body Checks the users credentials and returns a JWT if they log in successfully. Otherwise returns an error which is displayed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(
            body: Login,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = AccountApiFetchParamCreator(
                configuration,
            ).login(body, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Checks the email provided and if exists sends an email prompting the user to follow a link to reset their password
         * @summary requests a password reset
         * @param {ResetPasswordRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestResetPassword(
            body: ResetPasswordRequest,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = AccountApiFetchParamCreator(
                configuration,
            ).requestResetPassword(body, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Checks that the account exists by reset key, the key is still valid, and resets the password
         * @summary resets the users password, based on the reset key
         * @param {ResetPassword} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(
            body: ResetPassword,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = AccountApiFetchParamCreator(
                configuration,
            ).resetPassword(body, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Updates the users account, including password
         * @summary updates the users account
         * @param {UpdateAccount} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(
            body: UpdateAccount,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = AccountApiFetchParamCreator(
                configuration,
            ).updateAccount(body, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (
    configuration?: Configuration,
    fetch?: FetchAPI,
    basePath?: string,
) {
    return {
        /**
         * Creates a new account
         * @summary Creates a new account
         * @param {CreateUser} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountSignup(body: CreateUser, options?: any) {
            return AccountApiFp(configuration).accountSignup(body, options)(
                fetch,
                basePath,
            );
        },
        /**
         * Activates a new user account
         * @summary actviates a new user
         * @param {ActivateRequest} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateUser(body?: ActivateRequest, options?: any) {
            return AccountApiFp(configuration).activateUser(body, options)(
                fetch,
                basePath,
            );
        },
        /**
         * authenticates the user
         * @summary authenticates the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUser(options?: any) {
            return AccountApiFp(configuration).authenticateUser(options)(
                fetch,
                basePath,
            );
        },
        /**
         * gets the users account, including password
         * @summary gets the users account
         * @param {string} userId Unique Id for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(userId: string, options?: any) {
            return AccountApiFp(configuration).getAccount(userId, options)(
                fetch,
                basePath,
            );
        },
        /**
         *
         * @summary Login to account
         * @param {Login} body Checks the users credentials and returns a JWT if they log in successfully. Otherwise returns an error which is displayed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(body: Login, options?: any) {
            return AccountApiFp(configuration).login(body, options)(
                fetch,
                basePath,
            );
        },
        /**
         * Checks the email provided and if exists sends an email prompting the user to follow a link to reset their password
         * @summary requests a password reset
         * @param {ResetPasswordRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestResetPassword(body: ResetPasswordRequest, options?: any) {
            return AccountApiFp(configuration).requestResetPassword(
                body,
                options,
            )(fetch, basePath);
        },
        /**
         * Checks that the account exists by reset key, the key is still valid, and resets the password
         * @summary resets the users password, based on the reset key
         * @param {ResetPassword} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(body: ResetPassword, options?: any) {
            return AccountApiFp(configuration).resetPassword(body, options)(
                fetch,
                basePath,
            );
        },
        /**
         * Updates the users account, including password
         * @summary updates the users account
         * @param {UpdateAccount} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(body: UpdateAccount, options?: any) {
            return AccountApiFp(configuration).updateAccount(body, options)(
                fetch,
                basePath,
            );
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * Creates a new account
     * @summary Creates a new account
     * @param {CreateUser} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountSignup(body: CreateUser, options?: any) {
        return AccountApiFp(this.configuration).accountSignup(body, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * Activates a new user account
     * @summary actviates a new user
     * @param {ActivateRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public activateUser(body?: ActivateRequest, options?: any) {
        return AccountApiFp(this.configuration).activateUser(body, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * authenticates the user
     * @summary authenticates the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public authenticateUser(options?: any) {
        return AccountApiFp(this.configuration).authenticateUser(options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * gets the users account, including password
     * @summary gets the users account
     * @param {string} userId Unique Id for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAccount(userId: string, options?: any) {
        return AccountApiFp(this.configuration).getAccount(userId, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     *
     * @summary Login to account
     * @param {Login} body Checks the users credentials and returns a JWT if they log in successfully. Otherwise returns an error which is displayed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public login(body: Login, options?: any) {
        return AccountApiFp(this.configuration).login(body, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * Checks the email provided and if exists sends an email prompting the user to follow a link to reset their password
     * @summary requests a password reset
     * @param {ResetPasswordRequest} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public requestResetPassword(body: ResetPasswordRequest, options?: any) {
        return AccountApiFp(this.configuration).requestResetPassword(
            body,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * Checks that the account exists by reset key, the key is still valid, and resets the password
     * @summary resets the users password, based on the reset key
     * @param {ResetPassword} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public resetPassword(body: ResetPassword, options?: any) {
        return AccountApiFp(this.configuration).resetPassword(body, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * Updates the users account, including password
     * @summary updates the users account
     * @param {UpdateAccount} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateAccount(body: UpdateAccount, options?: any) {
        return AccountApiFp(this.configuration).updateAccount(body, options)(
            this.fetch,
            this.basePath,
        );
    }
}
/**
 * BookingApi - fetch parameter creator
 * @export
 */
export const BookingApiFetchParamCreator = function (
    configuration?: Configuration,
) {
    return {
        /**
         * returns a specific booking
         * @summary returns a booking
         * @param {string} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        booking(bookingId: string, options: any = {}): FetchArgs {
            // verify required parameter 'bookingId' is not null or undefined
            if (bookingId === null || bookingId === undefined) {
                throw new RequiredError(
                    "bookingId",
                    "Required parameter bookingId was null or undefined when calling booking.",
                );
            }
            const localVarPath = `/booking`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (bookingId !== undefined) {
                localVarQueryParameter["bookingId"] = bookingId;
            }

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Adds a child booking to an existing booking
         * @param {ChildBookingDetails} body
         * @param {string} bookingId unique id to identify parent booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingAddChildBookingBookingIdPost(
            body: ChildBookingDetails,
            bookingId: string,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling bookingAddChildBookingBookingIdPost.",
                );
            }
            // verify required parameter 'bookingId' is not null or undefined
            if (bookingId === null || bookingId === undefined) {
                throw new RequiredError(
                    "bookingId",
                    "Required parameter bookingId was null or undefined when calling bookingAddChildBookingBookingIdPost.",
                );
            }
            const localVarPath = `/booking/addChildBooking/{bookingId}`.replace(
                `{${"bookingId"}}`,
                encodeURIComponent(String(bookingId)),
            );
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"ChildBookingDetails" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Duplicates a manifest to another booking
         * @param {string} bookingId unique id to identify booking to copy to
         * @param {string} manifestId unique id to identify manifest to copy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDuplicatemanifestBookingIdManifestIdPost(
            bookingId: string,
            manifestId: string,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'bookingId' is not null or undefined
            if (bookingId === null || bookingId === undefined) {
                throw new RequiredError(
                    "bookingId",
                    "Required parameter bookingId was null or undefined when calling bookingDuplicatemanifestBookingIdManifestIdPost.",
                );
            }
            // verify required parameter 'manifestId' is not null or undefined
            if (manifestId === null || manifestId === undefined) {
                throw new RequiredError(
                    "manifestId",
                    "Required parameter manifestId was null or undefined when calling bookingDuplicatemanifestBookingIdManifestIdPost.",
                );
            }
            const localVarPath =
                `/booking/duplicatemanifest/{bookingId}/{manifestId}`
                    .replace(
                        `{${"bookingId"}}`,
                        encodeURIComponent(String(bookingId)),
                    )
                    .replace(
                        `{${"manifestId"}}`,
                        encodeURIComponent(String(manifestId)),
                    );
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Depending on the day selected, activities may or may not be able to be selected.
         * @summary returns the available activitys
         * @param {string} date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingListAvailableActivitiesByDayGet(
            date: string,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError(
                    "date",
                    "Required parameter date was null or undefined when calling bookingListAvailableActivitiesByDayGet.",
                );
            }
            const localVarPath = `/booking/listAvailableActivitiesByDay`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter["date"] = date;
            }

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists days in week that have availabale slots
         * @summary returns list of days with some availability
         * @param {string} date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingListAvailableDatesByWeekGet(
            date: string,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError(
                    "date",
                    "Required parameter date was null or undefined when calling bookingListAvailableDatesByWeekGet.",
                );
            }
            const localVarPath = `/booking/listAvailableDatesByWeek`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter["date"] = date;
            }

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns the available slots per the day with status
         * @summary returns the list of slots per day
         * @param {string} date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingListAvailableTimesByDayGet(
            date: string,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError(
                    "date",
                    "Required parameter date was null or undefined when calling bookingListAvailableTimesByDayGet.",
                );
            }
            const localVarPath = `/booking/listAvailableTimesByDay`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter["date"] = date;
            }

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * filter dates
         * @summary returns the first and last booking dates, for filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingMinMaxDatesGet(options: any = {}): FetchArgs {
            const localVarPath = `/booking/minMaxDates`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Resends the booking confirmation to the client
         * @summary resend booking confirmation
         * @param {string} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingResendConfirmationBookingIdGet(
            bookingId: string,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'bookingId' is not null or undefined
            if (bookingId === null || bookingId === undefined) {
                throw new RequiredError(
                    "bookingId",
                    "Required parameter bookingId was null or undefined when calling bookingResendConfirmationBookingIdGet.",
                );
            }
            const localVarPath =
                `/booking/resendConfirmation/{bookingId}`.replace(
                    `{${"bookingId"}}`,
                    encodeURIComponent(String(bookingId)),
                );
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns the bookings created by the user
         * @summary returns a user's bookings
         * @param {string} userId a unique number to identify a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsByUser(userId: string, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError(
                    "userId",
                    "Required parameter userId was null or undefined when calling bookingsByUser.",
                );
            }
            const localVarPath = `/bookings/user/{userId}`.replace(
                `{${"userId"}}`,
                encodeURIComponent(String(userId)),
            );
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cancels a booking in the BAW and removes the event on google cal
         * @summary Cancels a booking
         * @param {CancelBooking} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelBooking(body: CancelBooking, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling cancelBooking.",
                );
            }
            const localVarPath = `/booking/cancel`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "PATCH" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"CancelBooking" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        /**
         * Complete a booking in the BAW
         * @summary Complete a booking
         * @param {CompleteBooking} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeBooking(body: CompleteBooking, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling completeBooking.",
                );
            }
            const localVarPath = `/booking/complete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "PATCH" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"CompleteBooking" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new unconfirmed booking
         * @summary Creates a new booking
         * @param {CreateBooking} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBooking(body: CreateBooking, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling createBooking.",
                );
            }
            const localVarPath = `/booking/create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"CreateBooking" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sets the crew's choice to accept the booking
         * @summary crew accepts booking
         * @param {CrewAuthBooking} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crewAcceptBooking(body: CrewAuthBooking, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling crewAcceptBooking.",
                );
            }
            const localVarPath = `/booking/crewAccept`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"CrewAuthBooking" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sets the crew's choice to reject the booking
         * @summary crew rejects booking
         * @param {CrewAuthBooking} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crewRejectBooking(body: CrewAuthBooking, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling crewRejectBooking.",
                );
            }
            const localVarPath = `/booking/crewReject`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"CrewAuthBooking" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sets crew to null
         * @summary sets crew to null
         * @param {string} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crewRemove(bookingId: string, options: any = {}): FetchArgs {
            // verify required parameter 'bookingId' is not null or undefined
            if (bookingId === null || bookingId === undefined) {
                throw new RequiredError(
                    "bookingId",
                    "Required parameter bookingId was null or undefined when calling crewRemove.",
                );
            }
            const localVarPath = `/booking/removeCrew`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (bookingId !== undefined) {
                localVarQueryParameter["bookingId"] = bookingId;
            }

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the disclaimer that is against the bookingId
         * @summary gets the disclaimer
         * @param {number} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disclaimer(bookingId: number, options: any = {}): FetchArgs {
            // verify required parameter 'bookingId' is not null or undefined
            if (bookingId === null || bookingId === undefined) {
                throw new RequiredError(
                    "bookingId",
                    "Required parameter bookingId was null or undefined when calling disclaimer.",
                );
            }
            const localVarPath = `/booking/disclaimer`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (bookingId !== undefined) {
                localVarQueryParameter["bookingId"] = bookingId;
            }

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Once the disclaimer has been completed this is fired to set that in the DB
         * @summary flags disclaimer as complete
         * @param {BookingDisclaimerModel} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disclaimerComplete(
            body: BookingDisclaimerModel,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling disclaimerComplete.",
                );
            }
            const localVarPath = `/booking/disclaimerComplete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"BookingDisclaimerModel" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of all bookings, must be an admin/skipper to use. can pass in if the list is unconfirmed or confirmed bookings only. Can also optionally pass in a date range
         * @summary returns list of all bookings
         * @param {string} [status]
         * @param {string} [from]
         * @param {number} [noOfDays]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBookingsByStatus(
            status?: string,
            from?: string,
            noOfDays?: number,
            options: any = {},
        ): FetchArgs {
            const localVarPath = `/bookings/listBookingsByStatus`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (status !== undefined) {
                localVarQueryParameter["status"] = status;
            }

            if (from !== undefined) {
                localVarQueryParameter["from"] = new Date(from).toISOString();
            }

            if (noOfDays !== undefined) {
                localVarQueryParameter["noOfDays"] = noOfDays;
            }

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of all crew confirmed bookings, must be an admin/skipper/crew to use. Must pass in boolean of whether it's the crew member's bookings or other crew members' bookings
         * @summary returns list of all bookings
         * @param {boolean} isCrewsOwnBookings
         * @param {string} [status]
         * @param {string} [from]
         * @param {number} [noOfDays]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCrewConfirmedBookings(
            isCrewsOwnBookings: boolean,
            status?: string,
            from?: string,
            noOfDays?: number,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'isCrewsOwnBookings' is not null or undefined
            if (
                isCrewsOwnBookings === null ||
                isCrewsOwnBookings === undefined
            ) {
                throw new RequiredError(
                    "isCrewsOwnBookings",
                    "Required parameter isCrewsOwnBookings was null or undefined when calling listCrewConfirmedBookings.",
                );
            }
            const localVarPath = `/bookings/listCrewConfirmedBookings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (status !== undefined) {
                localVarQueryParameter["status"] = status;
            }

            if (from !== undefined) {
                localVarQueryParameter["from"] = new Date(from).toISOString();
            }

            if (noOfDays !== undefined) {
                localVarQueryParameter["noOfDays"] = noOfDays;
            }

            if (isCrewsOwnBookings !== undefined) {
                localVarQueryParameter["isCrewsOwnBookings"] =
                    isCrewsOwnBookings;
            }

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of all crew unconfirmed bookings, must be an admin/skipper/crew to use.
         * @summary returns list of all bookings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCrewUnconfirmedBookings(options: any = {}): FetchArgs {
            const localVarPath = `/bookings/listCrewUnconfirmedBookings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Once the manifest has been completed this is fired to set that in the DB
         * @summary flags manifest as complete
         * @param {number} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manifestComplete(bookingId: number, options: any = {}): FetchArgs {
            // verify required parameter 'bookingId' is not null or undefined
            if (bookingId === null || bookingId === undefined) {
                throw new RequiredError(
                    "bookingId",
                    "Required parameter bookingId was null or undefined when calling manifestComplete.",
                );
            }
            const localVarPath = `/booking/manifestComplete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (bookingId !== undefined) {
                localVarQueryParameter["bookingId"] = bookingId;
            }

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of bookings related to the currently logged in user. can pass in if the list is unconfirmed or confirmed bookings only, or all. For “clients” this is their bookings. For “skippers”, it’s assigned bookings. Not relevant for admins
         * @summary returns list of bookings
         * @param {string} [time] time of bookings (default is upcoming)
         * @param {string} [status] status of bookings returning
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myBookings(
            time?: string,
            status?: string,
            options: any = {},
        ): FetchArgs {
            const localVarPath = `/bookings/myBookings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (time !== undefined) {
                localVarQueryParameter["time"] = time;
            }

            if (status !== undefined) {
                localVarQueryParameter["status"] = status;
            }

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sets the skippers choice to accept the booking
         * @summary skipper accepts booking
         * @param {SkipperAuthBooking} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperAcceptBooking(
            body: SkipperAuthBooking,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling skipperAcceptBooking.",
                );
            }
            const localVarPath = `/booking/accept`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"SkipperAuthBooking" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * More des
         * @summary returns list of all bookings for a skipper
         * @param {string} [from]
         * @param {number} [noOfDays]
         * @param {boolean} [isMyBookings]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperConfirmedBookingsByDate(
            from?: string,
            noOfDays?: number,
            isMyBookings?: boolean,
            options: any = {},
        ): FetchArgs {
            const localVarPath = `/bookings/skipperConfirmedBookingsByDate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (from !== undefined) {
                localVarQueryParameter["from"] = new Date(from).toISOString();
            }

            if (noOfDays !== undefined) {
                localVarQueryParameter["noOfDays"] = noOfDays;
            }

            if (isMyBookings !== undefined) {
                localVarQueryParameter["isMyBookings"] = isMyBookings;
            }

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sets the skippers choice to reject the booking
         * @summary skipper rejects booking
         * @param {SkipperAuthBooking} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperRejectBooking(
            body: SkipperAuthBooking,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling skipperRejectBooking.",
                );
            }
            const localVarPath = `/booking/reject`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"SkipperAuthBooking" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sets to null
         * @summary sets skipper to null
         * @param {string} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperRemove(bookingId: string, options: any = {}): FetchArgs {
            // verify required parameter 'bookingId' is not null or undefined
            if (bookingId === null || bookingId === undefined) {
                throw new RequiredError(
                    "bookingId",
                    "Required parameter bookingId was null or undefined when calling skipperRemove.",
                );
            }
            const localVarPath = `/booking/removeSkipper`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (bookingId !== undefined) {
                localVarQueryParameter["bookingId"] = bookingId;
            }

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * updates a specific booking
         * @summary updates a booking
         * @param {CreateBooking} body
         * @param {number} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBooking(
            body: CreateBooking,
            bookingId: number,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling updateBooking.",
                );
            }
            // verify required parameter 'bookingId' is not null or undefined
            if (bookingId === null || bookingId === undefined) {
                throw new RequiredError(
                    "bookingId",
                    "Required parameter bookingId was null or undefined when calling updateBooking.",
                );
            }
            const localVarPath = `/booking`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "PATCH" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (bookingId !== undefined) {
                localVarQueryParameter["bookingId"] = bookingId;
            }

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"CreateBooking" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * BookingApi - functional programming interface
 * @export
 */
export const BookingApiFp = function (configuration?: Configuration) {
    return {
        /**
         * returns a specific booking
         * @summary returns a booking
         * @param {string} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        booking(
            bookingId: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).booking(bookingId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Adds a child booking to an existing booking
         * @param {ChildBookingDetails} body
         * @param {string} bookingId unique id to identify parent booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingAddChildBookingBookingIdPost(
            body: ChildBookingDetails,
            bookingId: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).bookingAddChildBookingBookingIdPost(body, bookingId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Duplicates a manifest to another booking
         * @param {string} bookingId unique id to identify booking to copy to
         * @param {string} manifestId unique id to identify manifest to copy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDuplicatemanifestBookingIdManifestIdPost(
            bookingId: string,
            manifestId: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).bookingDuplicatemanifestBookingIdManifestIdPost(
                bookingId,
                manifestId,
                options,
            );
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Depending on the day selected, activities may or may not be able to be selected.
         * @summary returns the available activitys
         * @param {string} date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingListAvailableActivitiesByDayGet(
            date: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).bookingListAvailableActivitiesByDayGet(date, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Lists days in week that have availabale slots
         * @summary returns list of days with some availability
         * @param {string} date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingListAvailableDatesByWeekGet(
            date: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).bookingListAvailableDatesByWeekGet(date, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * returns the available slots per the day with status
         * @summary returns the list of slots per day
         * @param {string} date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingListAvailableTimesByDayGet(
            date: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).bookingListAvailableTimesByDayGet(date, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * filter dates
         * @summary returns the first and last booking dates, for filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingMinMaxDatesGet(
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs =
                BookingApiFetchParamCreator(
                    configuration,
                ).bookingMinMaxDatesGet(options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Resends the booking confirmation to the client
         * @summary resend booking confirmation
         * @param {string} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingResendConfirmationBookingIdGet(
            bookingId: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).bookingResendConfirmationBookingIdGet(bookingId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * returns the bookings created by the user
         * @summary returns a user's bookings
         * @param {string} userId a unique number to identify a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsByUser(
            userId: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).bookingsByUser(userId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Cancels a booking in the BAW and removes the event on google cal
         * @summary Cancels a booking
         * @param {CancelBooking} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelBooking(
            body: CancelBooking,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).cancelBooking(body, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Complete a booking in the BAW
         * @summary Complete a booking
         * @param {CompleteBooking} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeBooking(
            body: CompleteBooking,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).completeBooking(body, options);
            return (
                fetch: FetchAPI = isomorphicFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Creates a new unconfirmed booking
         * @summary Creates a new booking
         * @param {CreateBooking} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBooking(
            body: CreateBooking,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).createBooking(body, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sets the crew's choice to accept the booking
         * @summary crew accepts booking
         * @param {CrewAuthBooking} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crewAcceptBooking(
            body: CrewAuthBooking,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).crewAcceptBooking(body, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sets the crew's choice to reject the booking
         * @summary crew rejects booking
         * @param {CrewAuthBooking} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crewRejectBooking(
            body: CrewAuthBooking,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).crewRejectBooking(body, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * sets crew to null
         * @summary sets crew to null
         * @param {string} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crewRemove(
            bookingId: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).crewRemove(bookingId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Returns the disclaimer that is against the bookingId
         * @summary gets the disclaimer
         * @param {number} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disclaimer(
            bookingId: number,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).disclaimer(bookingId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Once the disclaimer has been completed this is fired to set that in the DB
         * @summary flags disclaimer as complete
         * @param {BookingDisclaimerModel} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disclaimerComplete(
            body: BookingDisclaimerModel,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).disclaimerComplete(body, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Returns a list of all bookings, must be an admin/skipper to use. can pass in if the list is unconfirmed or confirmed bookings only. Can also optionally pass in a date range
         * @summary returns list of all bookings
         * @param {string} [status]
         * @param {string} [from]
         * @param {number} [noOfDays]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBookingsByStatus(
            status?: string,
            from?: string,
            noOfDays?: number,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).listBookingsByStatus(status, from, noOfDays, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Returns a list of all crew confirmed bookings, must be an admin/skipper/crew to use. Must pass in boolean of whether it's the crew member's bookings or other crew members' bookings
         * @summary returns list of all bookings
         * @param {boolean} isCrewsOwnBookings
         * @param {string} [status]
         * @param {string} [from]
         * @param {number} [noOfDays]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCrewConfirmedBookings(
            isCrewsOwnBookings: boolean,
            status?: string,
            from?: string,
            noOfDays?: number,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).listCrewConfirmedBookings(
                isCrewsOwnBookings,
                status,
                from,
                noOfDays,
                options,
            );
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Returns a list of all crew unconfirmed bookings, must be an admin/skipper/crew to use.
         * @summary returns list of all bookings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCrewUnconfirmedBookings(
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs =
                BookingApiFetchParamCreator(
                    configuration,
                ).listCrewUnconfirmedBookings(options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Once the manifest has been completed this is fired to set that in the DB
         * @summary flags manifest as complete
         * @param {number} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manifestComplete(
            bookingId: number,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).manifestComplete(bookingId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Returns a list of bookings related to the currently logged in user. can pass in if the list is unconfirmed or confirmed bookings only, or all. For “clients” this is their bookings. For “skippers”, it’s assigned bookings. Not relevant for admins
         * @summary returns list of bookings
         * @param {string} [time] time of bookings (default is upcoming)
         * @param {string} [status] status of bookings returning
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myBookings(
            time?: string,
            status?: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).myBookings(time, status, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sets the skippers choice to accept the booking
         * @summary skipper accepts booking
         * @param {SkipperAuthBooking} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperAcceptBooking(
            body: SkipperAuthBooking,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).skipperAcceptBooking(body, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * More des
         * @summary returns list of all bookings for a skipper
         * @param {string} [from]
         * @param {number} [noOfDays]
         * @param {boolean} [isMyBookings]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperConfirmedBookingsByDate(
            from?: string,
            noOfDays?: number,
            isMyBookings?: boolean,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).skipperConfirmedBookingsByDate(
                from,
                noOfDays,
                isMyBookings,
                options,
            );
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sets the skippers choice to reject the booking
         * @summary skipper rejects booking
         * @param {SkipperAuthBooking} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperRejectBooking(
            body: SkipperAuthBooking,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).skipperRejectBooking(body, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * sets to null
         * @summary sets skipper to null
         * @param {string} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperRemove(
            bookingId: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).skipperRemove(bookingId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * updates a specific booking
         * @summary updates a booking
         * @param {CreateBooking} body
         * @param {number} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBooking(
            body: CreateBooking,
            bookingId: number,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = BookingApiFetchParamCreator(
                configuration,
            ).updateBooking(body, bookingId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * BookingApi - factory interface
 * @export
 */
export const BookingApiFactory = function (
    configuration?: Configuration,
    fetch?: FetchAPI,
    basePath?: string,
) {
    return {
        /**
         * returns a specific booking
         * @summary returns a booking
         * @param {string} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        booking(bookingId: string, options?: any) {
            return BookingApiFp(configuration).booking(bookingId, options)(
                fetch,
                basePath,
            );
        },
        /**
         *
         * @summary Adds a child booking to an existing booking
         * @param {ChildBookingDetails} body
         * @param {string} bookingId unique id to identify parent booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingAddChildBookingBookingIdPost(
            body: ChildBookingDetails,
            bookingId: string,
            options?: any,
        ) {
            return BookingApiFp(
                configuration,
            ).bookingAddChildBookingBookingIdPost(
                body,
                bookingId,
                options,
            )(fetch, basePath);
        },
        /**
         *
         * @summary Duplicates a manifest to another booking
         * @param {string} bookingId unique id to identify booking to copy to
         * @param {string} manifestId unique id to identify manifest to copy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDuplicatemanifestBookingIdManifestIdPost(
            bookingId: string,
            manifestId: string,
            options?: any,
        ) {
            return BookingApiFp(
                configuration,
            ).bookingDuplicatemanifestBookingIdManifestIdPost(
                bookingId,
                manifestId,
                options,
            )(fetch, basePath);
        },
        /**
         * Depending on the day selected, activities may or may not be able to be selected.
         * @summary returns the available activitys
         * @param {string} date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingListAvailableActivitiesByDayGet(date: string, options?: any) {
            return BookingApiFp(
                configuration,
            ).bookingListAvailableActivitiesByDayGet(date, options)(
                fetch,
                basePath,
            );
        },
        /**
         * Lists days in week that have availabale slots
         * @summary returns list of days with some availability
         * @param {string} date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingListAvailableDatesByWeekGet(date: string, options?: any) {
            return BookingApiFp(
                configuration,
            ).bookingListAvailableDatesByWeekGet(date, options)(
                fetch,
                basePath,
            );
        },
        /**
         * returns the available slots per the day with status
         * @summary returns the list of slots per day
         * @param {string} date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingListAvailableTimesByDayGet(date: string, options?: any) {
            return BookingApiFp(
                configuration,
            ).bookingListAvailableTimesByDayGet(date, options)(fetch, basePath);
        },
        /**
         * filter dates
         * @summary returns the first and last booking dates, for filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingMinMaxDatesGet(options?: any) {
            return BookingApiFp(configuration).bookingMinMaxDatesGet(options)(
                fetch,
                basePath,
            );
        },
        /**
         * Resends the booking confirmation to the client
         * @summary resend booking confirmation
         * @param {string} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingResendConfirmationBookingIdGet(
            bookingId: string,
            options?: any,
        ) {
            return BookingApiFp(
                configuration,
            ).bookingResendConfirmationBookingIdGet(bookingId, options)(
                fetch,
                basePath,
            );
        },
        /**
         * returns the bookings created by the user
         * @summary returns a user's bookings
         * @param {string} userId a unique number to identify a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsByUser(userId: string, options?: any) {
            return BookingApiFp(configuration).bookingsByUser(userId, options)(
                fetch,
                basePath,
            );
        },
        /**
         * Cancels a booking in the BAW and removes the event on google cal
         * @summary Cancels a booking
         * @param {CancelBooking} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelBooking(body: CancelBooking, options?: any) {
            return BookingApiFp(configuration).cancelBooking(body, options)(
                fetch,
                basePath,
            );
        },
        /**
         * Creates a new unconfirmed booking
         * @summary Creates a new booking
         * @param {CreateBooking} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBooking(body: CreateBooking, options?: any) {
            return BookingApiFp(configuration).createBooking(body, options)(
                fetch,
                basePath,
            );
        },
        /**
         * Complete a booking in the BAW
         * @summary Complete a booking
         * @param {CompleteBooking} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeBooking(body: CompleteBooking, options?: any) {
            return BookingApiFp(configuration).completeBooking(body, options)(
                fetch,
                basePath,
            );
        },
        /**
         * Sets the crew's choice to accept the booking
         * @summary crew accepts booking
         * @param {CrewAuthBooking} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crewAcceptBooking(body: CrewAuthBooking, options?: any) {
            return BookingApiFp(configuration).crewAcceptBooking(body, options)(
                fetch,
                basePath,
            );
        },
        /**
         * Sets the crew's choice to reject the booking
         * @summary crew rejects booking
         * @param {CrewAuthBooking} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crewRejectBooking(body: CrewAuthBooking, options?: any) {
            return BookingApiFp(configuration).crewRejectBooking(body, options)(
                fetch,
                basePath,
            );
        },
        /**
         * sets crew to null
         * @summary sets crew to null
         * @param {string} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crewRemove(bookingId: string, options?: any) {
            return BookingApiFp(configuration).crewRemove(bookingId, options)(
                fetch,
                basePath,
            );
        },
        /**
         * Returns the disclaimer that is against the bookingId
         * @summary gets the disclaimer
         * @param {number} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disclaimer(bookingId: number, options?: any) {
            return BookingApiFp(configuration).disclaimer(bookingId, options)(
                fetch,
                basePath,
            );
        },
        /**
         * Once the disclaimer has been completed this is fired to set that in the DB
         * @summary flags disclaimer as complete
         * @param {BookingDisclaimerModel} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disclaimerComplete(body: BookingDisclaimerModel, options?: any) {
            return BookingApiFp(configuration).disclaimerComplete(
                body,
                options,
            )(fetch, basePath);
        },
        /**
         * Returns a list of all bookings, must be an admin/skipper to use. can pass in if the list is unconfirmed or confirmed bookings only. Can also optionally pass in a date range
         * @summary returns list of all bookings
         * @param {string} [status]
         * @param {string} [from]
         * @param {number} [noOfDays]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBookingsByStatus(
            status?: string,
            from?: string,
            noOfDays?: number,
            options?: any,
        ) {
            return BookingApiFp(configuration).listBookingsByStatus(
                status,
                from,
                noOfDays,
                options,
            )(fetch, basePath);
        },
        /**
         * Returns a list of all crew confirmed bookings, must be an admin/skipper/crew to use. Must pass in boolean of whether it's the crew member's bookings or other crew members' bookings
         * @summary returns list of all bookings
         * @param {boolean} isCrewsOwnBookings
         * @param {string} [status]
         * @param {string} [from]
         * @param {number} [noOfDays]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCrewConfirmedBookings(
            isCrewsOwnBookings: boolean,
            status?: string,
            from?: string,
            noOfDays?: number,
            options?: any,
        ) {
            return BookingApiFp(configuration).listCrewConfirmedBookings(
                isCrewsOwnBookings,
                status,
                from,
                noOfDays,
                options,
            )(fetch, basePath);
        },
        /**
         * Returns a list of all crew unconfirmed bookings, must be an admin/skipper/crew to use.
         * @summary returns list of all bookings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCrewUnconfirmedBookings(options?: any) {
            return BookingApiFp(configuration).listCrewUnconfirmedBookings(
                options,
            )(fetch, basePath);
        },
        /**
         * Once the manifest has been completed this is fired to set that in the DB
         * @summary flags manifest as complete
         * @param {number} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manifestComplete(bookingId: number, options?: any) {
            return BookingApiFp(configuration).manifestComplete(
                bookingId,
                options,
            )(fetch, basePath);
        },
        /**
         * Returns a list of bookings related to the currently logged in user. can pass in if the list is unconfirmed or confirmed bookings only, or all. For “clients” this is their bookings. For “skippers”, it’s assigned bookings. Not relevant for admins
         * @summary returns list of bookings
         * @param {string} [time] time of bookings (default is upcoming)
         * @param {string} [status] status of bookings returning
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myBookings(time?: string, status?: string, options?: any) {
            return BookingApiFp(configuration).myBookings(
                time,
                status,
                options,
            )(fetch, basePath);
        },
        /**
         * Sets the skippers choice to accept the booking
         * @summary skipper accepts booking
         * @param {SkipperAuthBooking} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperAcceptBooking(body: SkipperAuthBooking, options?: any) {
            return BookingApiFp(configuration).skipperAcceptBooking(
                body,
                options,
            )(fetch, basePath);
        },
        /**
         * More des
         * @summary returns list of all bookings for a skipper
         * @param {string} [from]
         * @param {number} [noOfDays]
         * @param {boolean} [isMyBookings]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperConfirmedBookingsByDate(
            from?: string,
            noOfDays?: number,
            isMyBookings?: boolean,
            options?: any,
        ) {
            return BookingApiFp(configuration).skipperConfirmedBookingsByDate(
                from,
                noOfDays,
                isMyBookings,
                options,
            )(fetch, basePath);
        },
        /**
         * Sets the skippers choice to reject the booking
         * @summary skipper rejects booking
         * @param {SkipperAuthBooking} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperRejectBooking(body: SkipperAuthBooking, options?: any) {
            return BookingApiFp(configuration).skipperRejectBooking(
                body,
                options,
            )(fetch, basePath);
        },
        /**
         * sets to null
         * @summary sets skipper to null
         * @param {string} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperRemove(bookingId: string, options?: any) {
            return BookingApiFp(configuration).skipperRemove(
                bookingId,
                options,
            )(fetch, basePath);
        },
        /**
         * updates a specific booking
         * @summary updates a booking
         * @param {CreateBooking} body
         * @param {number} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBooking(body: CreateBooking, bookingId: number, options?: any) {
            return BookingApiFp(configuration).updateBooking(
                body,
                bookingId,
                options,
            )(fetch, basePath);
        },
    };
};

/**
 * BookingApi - object-oriented interface
 * @export
 * @class BookingApi
 * @extends {BaseAPI}
 */
export class BookingApi extends BaseAPI {
    /**
     * returns a specific booking
     * @summary returns a booking
     * @param {string} bookingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public booking(bookingId: string, options?: any) {
        return BookingApiFp(this.configuration).booking(bookingId, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     *
     * @summary Adds a child booking to an existing booking
     * @param {ChildBookingDetails} body
     * @param {string} bookingId unique id to identify parent booking ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public bookingAddChildBookingBookingIdPost(
        body: ChildBookingDetails,
        bookingId: string,
        options?: any,
    ) {
        return BookingApiFp(
            this.configuration,
        ).bookingAddChildBookingBookingIdPost(
            body,
            bookingId,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     *
     * @summary Duplicates a manifest to another booking
     * @param {string} bookingId unique id to identify booking to copy to
     * @param {string} manifestId unique id to identify manifest to copy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public bookingDuplicatemanifestBookingIdManifestIdPost(
        bookingId: string,
        manifestId: string,
        options?: any,
    ) {
        return BookingApiFp(
            this.configuration,
        ).bookingDuplicatemanifestBookingIdManifestIdPost(
            bookingId,
            manifestId,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * Depending on the day selected, activities may or may not be able to be selected.
     * @summary returns the available activitys
     * @param {string} date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public bookingListAvailableActivitiesByDayGet(date: string, options?: any) {
        return BookingApiFp(
            this.configuration,
        ).bookingListAvailableActivitiesByDayGet(date, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * Lists days in week that have availabale slots
     * @summary returns list of days with some availability
     * @param {string} date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public bookingListAvailableDatesByWeekGet(date: string, options?: any) {
        return BookingApiFp(
            this.configuration,
        ).bookingListAvailableDatesByWeekGet(date, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * returns the available slots per the day with status
     * @summary returns the list of slots per day
     * @param {string} date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public bookingListAvailableTimesByDayGet(date: string, options?: any) {
        return BookingApiFp(
            this.configuration,
        ).bookingListAvailableTimesByDayGet(date, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * filter dates
     * @summary returns the first and last booking dates, for filters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public bookingMinMaxDatesGet(options?: any) {
        return BookingApiFp(this.configuration).bookingMinMaxDatesGet(options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * Resends the booking confirmation to the client
     * @summary resend booking confirmation
     * @param {string} bookingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public bookingResendConfirmationBookingIdGet(
        bookingId: string,
        options?: any,
    ) {
        return BookingApiFp(
            this.configuration,
        ).bookingResendConfirmationBookingIdGet(bookingId, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * returns the bookings created by the user
     * @summary returns a user's bookings
     * @param {string} userId a unique number to identify a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public bookingsByUser(userId: string, options?: any) {
        return BookingApiFp(this.configuration).bookingsByUser(userId, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * Cancels a booking in the BAW and removes the event on google cal
     * @summary Cancels a booking
     * @param {CancelBooking} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public cancelBooking(body: CancelBooking, options?: any) {
        return BookingApiFp(this.configuration).cancelBooking(body, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * Creates a new unconfirmed booking
     * @summary Creates a new booking
     * @param {CreateBooking} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public createBooking(body: CreateBooking, options?: any) {
        return BookingApiFp(this.configuration).createBooking(body, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * Sets the crew's choice to accept the booking
     * @summary crew accepts booking
     * @param {CrewAuthBooking} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public crewAcceptBooking(body: CrewAuthBooking, options?: any) {
        return BookingApiFp(this.configuration).crewAcceptBooking(
            body,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * Sets the crew's choice to reject the booking
     * @summary crew rejects booking
     * @param {CrewAuthBooking} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public crewRejectBooking(body: CrewAuthBooking, options?: any) {
        return BookingApiFp(this.configuration).crewRejectBooking(
            body,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * sets crew to null
     * @summary sets crew to null
     * @param {string} bookingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public crewRemove(bookingId: string, options?: any) {
        return BookingApiFp(this.configuration).crewRemove(bookingId, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * Returns the disclaimer that is against the bookingId
     * @summary gets the disclaimer
     * @param {number} bookingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public disclaimer(bookingId: number, options?: any) {
        return BookingApiFp(this.configuration).disclaimer(bookingId, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * Once the disclaimer has been completed this is fired to set that in the DB
     * @summary flags disclaimer as complete
     * @param {BookingDisclaimerModel} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public disclaimerComplete(body: BookingDisclaimerModel, options?: any) {
        return BookingApiFp(this.configuration).disclaimerComplete(
            body,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * Returns a list of all bookings, must be an admin/skipper to use. can pass in if the list is unconfirmed or confirmed bookings only. Can also optionally pass in a date range
     * @summary returns list of all bookings
     * @param {string} [status]
     * @param {string} [from]
     * @param {number} [noOfDays]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public listBookingsByStatus(
        status?: string,
        from?: string,
        noOfDays?: number,
        options?: any,
    ) {
        return BookingApiFp(this.configuration).listBookingsByStatus(
            status,
            from,
            noOfDays,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * Returns a list of all crew confirmed bookings, must be an admin/skipper/crew to use. Must pass in boolean of whether it's the crew member's bookings or other crew members' bookings
     * @summary returns list of all bookings
     * @param {boolean} isCrewsOwnBookings
     * @param {string} [status]
     * @param {string} [from]
     * @param {number} [noOfDays]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public listCrewConfirmedBookings(
        isCrewsOwnBookings: boolean,
        status?: string,
        from?: string,
        noOfDays?: number,
        options?: any,
    ) {
        return BookingApiFp(this.configuration).listCrewConfirmedBookings(
            isCrewsOwnBookings,
            status,
            from,
            noOfDays,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * Returns a list of all crew unconfirmed bookings, must be an admin/skipper/crew to use.
     * @summary returns list of all bookings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public listCrewUnconfirmedBookings(options?: any) {
        return BookingApiFp(this.configuration).listCrewUnconfirmedBookings(
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * Once the manifest has been completed this is fired to set that in the DB
     * @summary flags manifest as complete
     * @param {number} bookingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public manifestComplete(bookingId: number, options?: any) {
        return BookingApiFp(this.configuration).manifestComplete(
            bookingId,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * Returns a list of bookings related to the currently logged in user. can pass in if the list is unconfirmed or confirmed bookings only, or all. For “clients” this is their bookings. For “skippers”, it’s assigned bookings. Not relevant for admins
     * @summary returns list of bookings
     * @param {string} [time] time of bookings (default is upcoming)
     * @param {string} [status] status of bookings returning
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public myBookings(time?: string, status?: string, options?: any) {
        return BookingApiFp(this.configuration).myBookings(
            time,
            status,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * Sets the skippers choice to accept the booking
     * @summary skipper accepts booking
     * @param {SkipperAuthBooking} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public skipperAcceptBooking(body: SkipperAuthBooking, options?: any) {
        return BookingApiFp(this.configuration).skipperAcceptBooking(
            body,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * More des
     * @summary returns list of all bookings for a skipper
     * @param {string} [from]
     * @param {number} [noOfDays]
     * @param {boolean} [isMyBookings]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public skipperConfirmedBookingsByDate(
        from?: string,
        noOfDays?: number,
        isMyBookings?: boolean,
        options?: any,
    ) {
        return BookingApiFp(this.configuration).skipperConfirmedBookingsByDate(
            from,
            noOfDays,
            isMyBookings,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * Sets the skippers choice to reject the booking
     * @summary skipper rejects booking
     * @param {SkipperAuthBooking} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public skipperRejectBooking(body: SkipperAuthBooking, options?: any) {
        return BookingApiFp(this.configuration).skipperRejectBooking(
            body,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * sets to null
     * @summary sets skipper to null
     * @param {string} bookingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public skipperRemove(bookingId: string, options?: any) {
        return BookingApiFp(this.configuration).skipperRemove(
            bookingId,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * updates a specific booking
     * @summary updates a booking
     * @param {CreateBooking} body
     * @param {number} bookingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public updateBooking(
        body: CreateBooking,
        bookingId: number,
        options?: any,
    ) {
        return BookingApiFp(this.configuration).updateBooking(
            body,
            bookingId,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * Complete a booking in the BAW
     * @summary Complete a booking
     * @param {CompleteBooking} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public completeBooking(body: CompleteBooking, options?: any) {
        return BookingApiFp(this.configuration).completeBooking(body, options)(
            this.fetch,
            this.basePath,
        );
    }
}
/**
 * CrewApi - fetch parameter creator
 * @export
 */
export const CrewApiFetchParamCreator = function (
    configuration?: Configuration,
) {
    return {
        /**
         * Creates a crew member
         * @summary Create new crew member
         * @param {CreateCrew} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crewCreatePost(body: CreateCrew, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling crewCreatePost.",
                );
            }
            const localVarPath = `/crew/create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"CreateCrew" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete a crew by crewd
         * @summary delete a crew by crewId
         * @param {string} crewId unique id to identify crew
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crewDeleteDelete(crewId: string, options: any = {}): FetchArgs {
            // verify required parameter 'crewId' is not null or undefined
            if (crewId === null || crewId === undefined) {
                throw new RequiredError(
                    "crewId",
                    "Required parameter crewId was null or undefined when calling crewDeleteDelete.",
                );
            }
            const localVarPath = `/crew/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "DELETE" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (crewId !== undefined) {
                localVarQueryParameter["crewId"] = crewId;
            }

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * disables a crew by crewId
         * @summary disable crew by crewId
         * @param {string} crewId unique id to identify crew
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crewDisablePatch(crewId: string, options: any = {}): FetchArgs {
            // verify required parameter 'crewId' is not null or undefined
            if (crewId === null || crewId === undefined) {
                throw new RequiredError(
                    "crewId",
                    "Required parameter crewId was null or undefined when calling crewDisablePatch.",
                );
            }
            const localVarPath = `/crew/disable`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "PATCH" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (crewId !== undefined) {
                localVarQueryParameter["crewId"] = crewId;
            }

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns all crew
         * @summary returns all crew members
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCrew(options: any = {}): FetchArgs {
            const localVarPath = `/crew/getAllCrew`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * CrewApi - functional programming interface
 * @export
 */
export const CrewApiFp = function (configuration?: Configuration) {
    return {
        /**
         * Creates a crew member
         * @summary Create new crew member
         * @param {CreateCrew} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crewCreatePost(
            body: CreateCrew,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = CrewApiFetchParamCreator(
                configuration,
            ).crewCreatePost(body, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * delete a crew by crewd
         * @summary delete a crew by crewId
         * @param {string} crewId unique id to identify crew
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crewDeleteDelete(
            crewId: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = CrewApiFetchParamCreator(
                configuration,
            ).crewDeleteDelete(crewId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * disables a crew by crewId
         * @summary disable crew by crewId
         * @param {string} crewId unique id to identify crew
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crewDisablePatch(
            crewId: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = CrewApiFetchParamCreator(
                configuration,
            ).crewDisablePatch(crewId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * returns all crew
         * @summary returns all crew members
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCrew(
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs =
                CrewApiFetchParamCreator(configuration).getAllCrew(options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * CrewApi - factory interface
 * @export
 */
export const CrewApiFactory = function (
    configuration?: Configuration,
    fetch?: FetchAPI,
    basePath?: string,
) {
    return {
        /**
         * Creates a crew member
         * @summary Create new crew member
         * @param {CreateCrew} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crewCreatePost(body: CreateCrew, options?: any) {
            return CrewApiFp(configuration).crewCreatePost(body, options)(
                fetch,
                basePath,
            );
        },
        /**
         * delete a crew by crewd
         * @summary delete a crew by crewId
         * @param {string} crewId unique id to identify crew
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crewDeleteDelete(crewId: string, options?: any) {
            return CrewApiFp(configuration).crewDeleteDelete(crewId, options)(
                fetch,
                basePath,
            );
        },
        /**
         * disables a crew by crewId
         * @summary disable crew by crewId
         * @param {string} crewId unique id to identify crew
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crewDisablePatch(crewId: string, options?: any) {
            return CrewApiFp(configuration).crewDisablePatch(crewId, options)(
                fetch,
                basePath,
            );
        },
        /**
         * returns all crew
         * @summary returns all crew members
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCrew(options?: any) {
            return CrewApiFp(configuration).getAllCrew(options)(
                fetch,
                basePath,
            );
        },
    };
};

/**
 * CrewApi - object-oriented interface
 * @export
 * @class CrewApi
 * @extends {BaseAPI}
 */
export class CrewApi extends BaseAPI {
    /**
     * Creates a crew member
     * @summary Create new crew member
     * @param {CreateCrew} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewApi
     */
    public crewCreatePost(body: CreateCrew, options?: any) {
        return CrewApiFp(this.configuration).crewCreatePost(body, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * delete a crew by crewd
     * @summary delete a crew by crewId
     * @param {string} crewId unique id to identify crew
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewApi
     */
    public crewDeleteDelete(crewId: string, options?: any) {
        return CrewApiFp(this.configuration).crewDeleteDelete(crewId, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * disables a crew by crewId
     * @summary disable crew by crewId
     * @param {string} crewId unique id to identify crew
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewApi
     */
    public crewDisablePatch(crewId: string, options?: any) {
        return CrewApiFp(this.configuration).crewDisablePatch(crewId, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * returns all crew
     * @summary returns all crew members
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrewApi
     */
    public getAllCrew(options?: any) {
        return CrewApiFp(this.configuration).getAllCrew(options)(
            this.fetch,
            this.basePath,
        );
    }
}
/**
 * EmailApi - fetch parameter creator
 * @export
 */
export const EmailApiFetchParamCreator = function (
    configuration?: Configuration,
) {
    return {
        /**
         * Creates an individual email template
         * @summary Creates an individual email template
         * @param {EmailTemplate} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmailTemplate(body: EmailTemplate, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling createEmailTemplate.",
                );
            }
            const localVarPath = `/email/template/create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"EmailTemplate" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enqueus a new email taking in a boookingId and templateId
         * @summary Enqueus a new email
         * @param {EmailEnque} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailEnqueuePost(body: EmailEnque, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling emailEnqueuePost.",
                );
            }
            const localVarPath = `/email/enqueue`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"EmailEnque" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * removes an email template from the emailTemplate table
         * @summary Delete an email template
         * @param {string} emailId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplateDeleteEmailIdDelete(
            emailId: string,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'emailId' is not null or undefined
            if (emailId === null || emailId === undefined) {
                throw new RequiredError(
                    "emailId",
                    "Required parameter emailId was null or undefined when calling emailTemplateDeleteEmailIdDelete.",
                );
            }
            const localVarPath = `/email/template/delete/{emailId}`.replace(
                `{${"emailId"}}`,
                encodeURIComponent(String(emailId)),
            );
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "DELETE" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns an individual email template
         * @summary Returns an individual email template
         * @param {string} emailId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailTemplate(emailId: string, options: any = {}): FetchArgs {
            // verify required parameter 'emailId' is not null or undefined
            if (emailId === null || emailId === undefined) {
                throw new RequiredError(
                    "emailId",
                    "Required parameter emailId was null or undefined when calling getEmailTemplate.",
                );
            }
            const localVarPath = `/email/template/{emailId}`.replace(
                `{${"emailId"}}`,
                encodeURIComponent(String(emailId)),
            );
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * eturns a list of all email templates
         * @summary Returns a list of all email templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailTemplates(options: any = {}): FetchArgs {
            const localVarPath = `/email/templates`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns a list of queued emails
         * @param {string} status status of the email
         * @param {string} startDate Start date of the list of emails
         * @param {string} endDate End date of the list of emails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queuedEmails(
            status: string,
            startDate: string,
            endDate: string,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'status' is not null or undefined
            if (status === null || status === undefined) {
                throw new RequiredError(
                    "status",
                    "Required parameter status was null or undefined when calling queuedEmails.",
                );
            }
            // verify required parameter 'startDate' is not null or undefined
            if (startDate === null || startDate === undefined) {
                throw new RequiredError(
                    "startDate",
                    "Required parameter startDate was null or undefined when calling queuedEmails.",
                );
            }
            // verify required parameter 'endDate' is not null or undefined
            if (endDate === null || endDate === undefined) {
                throw new RequiredError(
                    "endDate",
                    "Required parameter endDate was null or undefined when calling queuedEmails.",
                );
            }
            const localVarPath = `/emails`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (status !== undefined) {
                localVarQueryParameter["status"] = status;
            }

            if (startDate !== undefined) {
                localVarQueryParameter["startDate"] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter["endDate"] = endDate;
            }

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Sends any queued emails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendQueuedEmails(options: any = {}): FetchArgs {
            const localVarPath = `/emails/sendQueued`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an individual email template
         * @summary Updates an individual email template
         * @param {EmailTemplate} body
         * @param {string} emailId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailTemplate(
            body: EmailTemplate,
            emailId: string,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling updateEmailTemplate.",
                );
            }
            // verify required parameter 'emailId' is not null or undefined
            if (emailId === null || emailId === undefined) {
                throw new RequiredError(
                    "emailId",
                    "Required parameter emailId was null or undefined when calling updateEmailTemplate.",
                );
            }
            const localVarPath = `/email/template/{emailId}`.replace(
                `{${"emailId"}}`,
                encodeURIComponent(String(emailId)),
            );
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "PATCH" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"EmailTemplate" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * EmailApi - functional programming interface
 * @export
 */
export const EmailApiFp = function (configuration?: Configuration) {
    return {
        /**
         * Creates an individual email template
         * @summary Creates an individual email template
         * @param {EmailTemplate} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmailTemplate(
            body: EmailTemplate,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = EmailApiFetchParamCreator(
                configuration,
            ).createEmailTemplate(body, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Enqueus a new email taking in a boookingId and templateId
         * @summary Enqueus a new email
         * @param {EmailEnque} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailEnqueuePost(
            body: EmailEnque,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = EmailApiFetchParamCreator(
                configuration,
            ).emailEnqueuePost(body, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * removes an email template from the emailTemplate table
         * @summary Delete an email template
         * @param {string} emailId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplateDeleteEmailIdDelete(
            emailId: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = EmailApiFetchParamCreator(
                configuration,
            ).emailTemplateDeleteEmailIdDelete(emailId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Returns an individual email template
         * @summary Returns an individual email template
         * @param {string} emailId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailTemplate(
            emailId: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = EmailApiFetchParamCreator(
                configuration,
            ).getEmailTemplate(emailId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * eturns a list of all email templates
         * @summary Returns a list of all email templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailTemplates(
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs =
                EmailApiFetchParamCreator(configuration).getEmailTemplates(
                    options,
                );
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Returns a list of queued emails
         * @param {string} status status of the email
         * @param {string} startDate Start date of the list of emails
         * @param {string} endDate End date of the list of emails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queuedEmails(
            status: string,
            startDate: string,
            endDate: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = EmailApiFetchParamCreator(
                configuration,
            ).queuedEmails(status, startDate, endDate, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Sends any queued emails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendQueuedEmails(
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs =
                EmailApiFetchParamCreator(configuration).sendQueuedEmails(
                    options,
                );
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Updates an individual email template
         * @summary Updates an individual email template
         * @param {EmailTemplate} body
         * @param {string} emailId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailTemplate(
            body: EmailTemplate,
            emailId: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = EmailApiFetchParamCreator(
                configuration,
            ).updateEmailTemplate(body, emailId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * EmailApi - factory interface
 * @export
 */
export const EmailApiFactory = function (
    configuration?: Configuration,
    fetch?: FetchAPI,
    basePath?: string,
) {
    return {
        /**
         * Creates an individual email template
         * @summary Creates an individual email template
         * @param {EmailTemplate} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmailTemplate(body: EmailTemplate, options?: any) {
            return EmailApiFp(configuration).createEmailTemplate(body, options)(
                fetch,
                basePath,
            );
        },
        /**
         * Enqueus a new email taking in a boookingId and templateId
         * @summary Enqueus a new email
         * @param {EmailEnque} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailEnqueuePost(body: EmailEnque, options?: any) {
            return EmailApiFp(configuration).emailEnqueuePost(body, options)(
                fetch,
                basePath,
            );
        },
        /**
         * removes an email template from the emailTemplate table
         * @summary Delete an email template
         * @param {string} emailId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplateDeleteEmailIdDelete(emailId: string, options?: any) {
            return EmailApiFp(configuration).emailTemplateDeleteEmailIdDelete(
                emailId,
                options,
            )(fetch, basePath);
        },
        /**
         * Returns an individual email template
         * @summary Returns an individual email template
         * @param {string} emailId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailTemplate(emailId: string, options?: any) {
            return EmailApiFp(configuration).getEmailTemplate(emailId, options)(
                fetch,
                basePath,
            );
        },
        /**
         * eturns a list of all email templates
         * @summary Returns a list of all email templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailTemplates(options?: any) {
            return EmailApiFp(configuration).getEmailTemplates(options)(
                fetch,
                basePath,
            );
        },
        /**
         *
         * @summary Returns a list of queued emails
         * @param {string} status status of the email
         * @param {string} startDate Start date of the list of emails
         * @param {string} endDate End date of the list of emails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queuedEmails(
            status: string,
            startDate: string,
            endDate: string,
            options?: any,
        ) {
            return EmailApiFp(configuration).queuedEmails(
                status,
                startDate,
                endDate,
                options,
            )(fetch, basePath);
        },
        /**
         *
         * @summary Sends any queued emails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendQueuedEmails(options?: any) {
            return EmailApiFp(configuration).sendQueuedEmails(options)(
                fetch,
                basePath,
            );
        },
        /**
         * Updates an individual email template
         * @summary Updates an individual email template
         * @param {EmailTemplate} body
         * @param {string} emailId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmailTemplate(
            body: EmailTemplate,
            emailId: string,
            options?: any,
        ) {
            return EmailApiFp(configuration).updateEmailTemplate(
                body,
                emailId,
                options,
            )(fetch, basePath);
        },
    };
};

/**
 * EmailApi - object-oriented interface
 * @export
 * @class EmailApi
 * @extends {BaseAPI}
 */
export class EmailApi extends BaseAPI {
    /**
     * Creates an individual email template
     * @summary Creates an individual email template
     * @param {EmailTemplate} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public createEmailTemplate(body: EmailTemplate, options?: any) {
        return EmailApiFp(this.configuration).createEmailTemplate(
            body,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * Enqueus a new email taking in a boookingId and templateId
     * @summary Enqueus a new email
     * @param {EmailEnque} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailEnqueuePost(body: EmailEnque, options?: any) {
        return EmailApiFp(this.configuration).emailEnqueuePost(body, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * removes an email template from the emailTemplate table
     * @summary Delete an email template
     * @param {string} emailId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailTemplateDeleteEmailIdDelete(emailId: string, options?: any) {
        return EmailApiFp(this.configuration).emailTemplateDeleteEmailIdDelete(
            emailId,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * Returns an individual email template
     * @summary Returns an individual email template
     * @param {string} emailId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public getEmailTemplate(emailId: string, options?: any) {
        return EmailApiFp(this.configuration).getEmailTemplate(
            emailId,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * eturns a list of all email templates
     * @summary Returns a list of all email templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public getEmailTemplates(options?: any) {
        return EmailApiFp(this.configuration).getEmailTemplates(options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     *
     * @summary Returns a list of queued emails
     * @param {string} status status of the email
     * @param {string} startDate Start date of the list of emails
     * @param {string} endDate End date of the list of emails
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public queuedEmails(
        status: string,
        startDate: string,
        endDate: string,
        options?: any,
    ) {
        return EmailApiFp(this.configuration).queuedEmails(
            status,
            startDate,
            endDate,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     *
     * @summary Sends any queued emails
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public sendQueuedEmails(options?: any) {
        return EmailApiFp(this.configuration).sendQueuedEmails(options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * Updates an individual email template
     * @summary Updates an individual email template
     * @param {EmailTemplate} body
     * @param {string} emailId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public updateEmailTemplate(
        body: EmailTemplate,
        emailId: string,
        options?: any,
    ) {
        return EmailApiFp(this.configuration).updateEmailTemplate(
            body,
            emailId,
            options,
        )(this.fetch, this.basePath);
    }
}
/**
 * EventApi - fetch parameter creator
 * @export
 */
export const EventApiFetchParamCreator = function (
    configuration?: Configuration,
) {
    return {
        /**
         * Returns a list of event types and their allocated slot size
         * @summary returns list of event types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityTypes(options: any = {}): FetchArgs {
            const localVarPath = `/events`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new Event Type
         * @summary Creates a new Event Type
         * @param {EventType} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventType(body: EventType, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling createEventType.",
                );
            }
            const localVarPath = `/event/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"EventType" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * deletes an event type
         * @summary deletes an event type
         * @param {string} eventId unique Id for event Type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventType(eventId: string, options: any = {}): FetchArgs {
            // verify required parameter 'eventId' is not null or undefined
            if (eventId === null || eventId === undefined) {
                throw new RequiredError(
                    "eventId",
                    "Required parameter eventId was null or undefined when calling deleteEventType.",
                );
            }
            const localVarPath = `/event/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "DELETE" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (eventId !== undefined) {
                localVarQueryParameter["eventId"] = eventId;
            }

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns an eventType by event type Id
         * @summary returns event type
         * @param {string} eventId Unique Id for event type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventType(eventId: string, options: any = {}): FetchArgs {
            // verify required parameter 'eventId' is not null or undefined
            if (eventId === null || eventId === undefined) {
                throw new RequiredError(
                    "eventId",
                    "Required parameter eventId was null or undefined when calling getEventType.",
                );
            }
            const localVarPath = `/event/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (eventId !== undefined) {
                localVarQueryParameter["eventId"] = eventId;
            }

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an event type
         * @summary Update an event type
         * @param {EventType} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventType(body: EventType, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling updateEventType.",
                );
            }
            const localVarPath = `/event/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "PATCH" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"EventType" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * EventApi - functional programming interface
 * @export
 */
export const EventApiFp = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of event types and their allocated slot size
         * @summary returns list of event types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityTypes(
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs =
                EventApiFetchParamCreator(configuration).activityTypes(options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Creates a new Event Type
         * @summary Creates a new Event Type
         * @param {EventType} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventType(
            body: EventType,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = EventApiFetchParamCreator(
                configuration,
            ).createEventType(body, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * deletes an event type
         * @summary deletes an event type
         * @param {string} eventId unique Id for event Type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventType(
            eventId: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = EventApiFetchParamCreator(
                configuration,
            ).deleteEventType(eventId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Returns an eventType by event type Id
         * @summary returns event type
         * @param {string} eventId Unique Id for event type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventType(
            eventId: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = EventApiFetchParamCreator(
                configuration,
            ).getEventType(eventId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Updates an event type
         * @summary Update an event type
         * @param {EventType} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventType(
            body: EventType,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = EventApiFetchParamCreator(
                configuration,
            ).updateEventType(body, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * EventApi - factory interface
 * @export
 */
export const EventApiFactory = function (
    configuration?: Configuration,
    fetch?: FetchAPI,
    basePath?: string,
) {
    return {
        /**
         * Returns a list of event types and their allocated slot size
         * @summary returns list of event types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityTypes(options?: any) {
            return EventApiFp(configuration).activityTypes(options)(
                fetch,
                basePath,
            );
        },
        /**
         * Creates a new Event Type
         * @summary Creates a new Event Type
         * @param {EventType} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventType(body: EventType, options?: any) {
            return EventApiFp(configuration).createEventType(body, options)(
                fetch,
                basePath,
            );
        },
        /**
         * deletes an event type
         * @summary deletes an event type
         * @param {string} eventId unique Id for event Type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventType(eventId: string, options?: any) {
            return EventApiFp(configuration).deleteEventType(eventId, options)(
                fetch,
                basePath,
            );
        },
        /**
         * Returns an eventType by event type Id
         * @summary returns event type
         * @param {string} eventId Unique Id for event type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventType(eventId: string, options?: any) {
            return EventApiFp(configuration).getEventType(eventId, options)(
                fetch,
                basePath,
            );
        },
        /**
         * Updates an event type
         * @summary Update an event type
         * @param {EventType} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventType(body: EventType, options?: any) {
            return EventApiFp(configuration).updateEventType(body, options)(
                fetch,
                basePath,
            );
        },
    };
};

/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
export class EventApi extends BaseAPI {
    /**
     * Returns a list of event types and their allocated slot size
     * @summary returns list of event types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public activityTypes(options?: any) {
        return EventApiFp(this.configuration).activityTypes(options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * Creates a new Event Type
     * @summary Creates a new Event Type
     * @param {EventType} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public createEventType(body: EventType, options?: any) {
        return EventApiFp(this.configuration).createEventType(body, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * deletes an event type
     * @summary deletes an event type
     * @param {string} eventId unique Id for event Type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public deleteEventType(eventId: string, options?: any) {
        return EventApiFp(this.configuration).deleteEventType(eventId, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * Returns an eventType by event type Id
     * @summary returns event type
     * @param {string} eventId Unique Id for event type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public getEventType(eventId: string, options?: any) {
        return EventApiFp(this.configuration).getEventType(eventId, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * Updates an event type
     * @summary Update an event type
     * @param {EventType} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public updateEventType(body: EventType, options?: any) {
        return EventApiFp(this.configuration).updateEventType(body, options)(
            this.fetch,
            this.basePath,
        );
    }
}
/**
 * ManifestApi - fetch parameter creator
 * @export
 */
export const ManifestApiFetchParamCreator = function (
    configuration?: Configuration,
) {
    return {
        /**
         * adds a passenger to a manifest
         * @summary add passenger to manifest
         * @param {PassengerManifest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manifestAddPassengerPost(
            body: PassengerManifest,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling manifestAddPassengerPost.",
                );
            }
            const localVarPath = `/manifest/addPassenger`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"PassengerManifest" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns a manifest by manifestId
         * @summary returns manifest by manifestId
         * @param {string} bookingId unique id to identify skipper
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manifestBookingIdGet(bookingId: string, options: any = {}): FetchArgs {
            // verify required parameter 'bookingId' is not null or undefined
            if (bookingId === null || bookingId === undefined) {
                throw new RequiredError(
                    "bookingId",
                    "Required parameter bookingId was null or undefined when calling manifestBookingIdGet.",
                );
            }
            const localVarPath = `/manifest/{bookingId}`.replace(
                `{${"bookingId"}}`,
                encodeURIComponent(String(bookingId)),
            );
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * creates a new manifest against a booking
         * @summary creates a new manifest
         * @param {Manifest} body unique id to identify skipper
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manifestCreatePost(body: Manifest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling manifestCreatePost.",
                );
            }
            const localVarPath = `/manifest/create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"Manifest" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary returns a download of bookings within the provided parameters
         * @param {string} bookingId unique id to identify booking
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manifestExportBookingIdGet(
            bookingId: string,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'bookingId' is not null or undefined
            if (bookingId === null || bookingId === undefined) {
                throw new RequiredError(
                    "bookingId",
                    "Required parameter bookingId was null or undefined when calling manifestExportBookingIdGet.",
                );
            }
            const localVarPath = `/manifest/export/{bookingId}`.replace(
                `{${"bookingId"}}`,
                encodeURIComponent(String(bookingId)),
            );
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * deletes the passenger
         * @summary deletes the passenger
         * @param {string} passengerId unique id to identify passenger
         * @param {string} manifestId unique id to identify manifest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manifestManifestIdPassengerPassengerIdDelete(
            passengerId: string,
            manifestId: string,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'passengerId' is not null or undefined
            if (passengerId === null || passengerId === undefined) {
                throw new RequiredError(
                    "passengerId",
                    "Required parameter passengerId was null or undefined when calling manifestManifestIdPassengerPassengerIdDelete.",
                );
            }
            // verify required parameter 'manifestId' is not null or undefined
            if (manifestId === null || manifestId === undefined) {
                throw new RequiredError(
                    "manifestId",
                    "Required parameter manifestId was null or undefined when calling manifestManifestIdPassengerPassengerIdDelete.",
                );
            }
            const localVarPath =
                `/manifest/{manifestId}/passenger/{passengerId}`
                    .replace(
                        `{${"passengerId"}}`,
                        encodeURIComponent(String(passengerId)),
                    )
                    .replace(
                        `{${"manifestId"}}`,
                        encodeURIComponent(String(manifestId)),
                    );
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "DELETE" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the passenger
         * @summary Updates the passenger
         * @param {string} passengerId unique id to identify passenger
         * @param {string} manifestId unique id to identify manifest
         * @param {UpdatePassengerManifest} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manifestManifestIdPassengerUpdatePassengerIdPatch(
            passengerId: string,
            manifestId: string,
            body?: UpdatePassengerManifest,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'passengerId' is not null or undefined
            if (passengerId === null || passengerId === undefined) {
                throw new RequiredError(
                    "passengerId",
                    "Required parameter passengerId was null or undefined when calling manifestManifestIdPassengerUpdatePassengerIdPatch.",
                );
            }
            // verify required parameter 'manifestId' is not null or undefined
            if (manifestId === null || manifestId === undefined) {
                throw new RequiredError(
                    "manifestId",
                    "Required parameter manifestId was null or undefined when calling manifestManifestIdPassengerUpdatePassengerIdPatch.",
                );
            }
            const localVarPath =
                `/manifest/{manifestId}/passengerUpdate/{passengerId}`
                    .replace(
                        `{${"passengerId"}}`,
                        encodeURIComponent(String(passengerId)),
                    )
                    .replace(
                        `{${"manifestId"}}`,
                        encodeURIComponent(String(manifestId)),
                    );
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "PATCH" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"UpdatePassengerManifest" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * ManifestApi - functional programming interface
 * @export
 */
export const ManifestApiFp = function (configuration?: Configuration) {
    return {
        /**
         * adds a passenger to a manifest
         * @summary add passenger to manifest
         * @param {PassengerManifest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manifestAddPassengerPost(
            body: PassengerManifest,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = ManifestApiFetchParamCreator(
                configuration,
            ).manifestAddPassengerPost(body, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * returns a manifest by manifestId
         * @summary returns manifest by manifestId
         * @param {string} bookingId unique id to identify skipper
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manifestBookingIdGet(
            bookingId: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = ManifestApiFetchParamCreator(
                configuration,
            ).manifestBookingIdGet(bookingId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * creates a new manifest against a booking
         * @summary creates a new manifest
         * @param {Manifest} body unique id to identify skipper
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manifestCreatePost(
            body: Manifest,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = ManifestApiFetchParamCreator(
                configuration,
            ).manifestCreatePost(body, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary returns a download of bookings within the provided parameters
         * @param {string} bookingId unique id to identify booking
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manifestExportBookingIdGet(
            bookingId: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = ManifestApiFetchParamCreator(
                configuration,
            ).manifestExportBookingIdGet(bookingId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * deletes the passenger
         * @summary deletes the passenger
         * @param {string} passengerId unique id to identify passenger
         * @param {string} manifestId unique id to identify manifest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manifestManifestIdPassengerPassengerIdDelete(
            passengerId: string,
            manifestId: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = ManifestApiFetchParamCreator(
                configuration,
            ).manifestManifestIdPassengerPassengerIdDelete(
                passengerId,
                manifestId,
                options,
            );
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Updates the passenger
         * @summary Updates the passenger
         * @param {string} passengerId unique id to identify passenger
         * @param {string} manifestId unique id to identify manifest
         * @param {UpdatePassengerManifest} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manifestManifestIdPassengerUpdatePassengerIdPatch(
            passengerId: string,
            manifestId: string,
            body?: UpdatePassengerManifest,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = ManifestApiFetchParamCreator(
                configuration,
            ).manifestManifestIdPassengerUpdatePassengerIdPatch(
                passengerId,
                manifestId,
                body,
                options,
            );
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * ManifestApi - factory interface
 * @export
 */
export const ManifestApiFactory = function (
    configuration?: Configuration,
    fetch?: FetchAPI,
    basePath?: string,
) {
    return {
        /**
         * adds a passenger to a manifest
         * @summary add passenger to manifest
         * @param {PassengerManifest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manifestAddPassengerPost(body: PassengerManifest, options?: any) {
            return ManifestApiFp(configuration).manifestAddPassengerPost(
                body,
                options,
            )(fetch, basePath);
        },
        /**
         * returns a manifest by manifestId
         * @summary returns manifest by manifestId
         * @param {string} bookingId unique id to identify skipper
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manifestBookingIdGet(bookingId: string, options?: any) {
            return ManifestApiFp(configuration).manifestBookingIdGet(
                bookingId,
                options,
            )(fetch, basePath);
        },
        /**
         * creates a new manifest against a booking
         * @summary creates a new manifest
         * @param {Manifest} body unique id to identify skipper
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manifestCreatePost(body: Manifest, options?: any) {
            return ManifestApiFp(configuration).manifestCreatePost(
                body,
                options,
            )(fetch, basePath);
        },
        /**
         *
         * @summary returns a download of bookings within the provided parameters
         * @param {string} bookingId unique id to identify booking
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manifestExportBookingIdGet(bookingId: string, options?: any) {
            return ManifestApiFp(configuration).manifestExportBookingIdGet(
                bookingId,
                options,
            )(fetch, basePath);
        },
        /**
         * deletes the passenger
         * @summary deletes the passenger
         * @param {string} passengerId unique id to identify passenger
         * @param {string} manifestId unique id to identify manifest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manifestManifestIdPassengerPassengerIdDelete(
            passengerId: string,
            manifestId: string,
            options?: any,
        ) {
            return ManifestApiFp(
                configuration,
            ).manifestManifestIdPassengerPassengerIdDelete(
                passengerId,
                manifestId,
                options,
            )(fetch, basePath);
        },
        /**
         * Updates the passenger
         * @summary Updates the passenger
         * @param {string} passengerId unique id to identify passenger
         * @param {string} manifestId unique id to identify manifest
         * @param {UpdatePassengerManifest} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manifestManifestIdPassengerUpdatePassengerIdPatch(
            passengerId: string,
            manifestId: string,
            body?: UpdatePassengerManifest,
            options?: any,
        ) {
            return ManifestApiFp(
                configuration,
            ).manifestManifestIdPassengerUpdatePassengerIdPatch(
                passengerId,
                manifestId,
                body,
                options,
            )(fetch, basePath);
        },
    };
};

/**
 * ManifestApi - object-oriented interface
 * @export
 * @class ManifestApi
 * @extends {BaseAPI}
 */
export class ManifestApi extends BaseAPI {
    /**
     * adds a passenger to a manifest
     * @summary add passenger to manifest
     * @param {PassengerManifest} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManifestApi
     */
    public manifestAddPassengerPost(body: PassengerManifest, options?: any) {
        return ManifestApiFp(this.configuration).manifestAddPassengerPost(
            body,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * returns a manifest by manifestId
     * @summary returns manifest by manifestId
     * @param {string} bookingId unique id to identify skipper
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManifestApi
     */
    public manifestBookingIdGet(bookingId: string, options?: any) {
        return ManifestApiFp(this.configuration).manifestBookingIdGet(
            bookingId,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * creates a new manifest against a booking
     * @summary creates a new manifest
     * @param {Manifest} body unique id to identify skipper
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManifestApi
     */
    public manifestCreatePost(body: Manifest, options?: any) {
        return ManifestApiFp(this.configuration).manifestCreatePost(
            body,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     *
     * @summary returns a download of bookings within the provided parameters
     * @param {string} bookingId unique id to identify booking
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManifestApi
     */
    public manifestExportBookingIdGet(bookingId: string, options?: any) {
        return ManifestApiFp(this.configuration).manifestExportBookingIdGet(
            bookingId,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * deletes the passenger
     * @summary deletes the passenger
     * @param {string} passengerId unique id to identify passenger
     * @param {string} manifestId unique id to identify manifest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManifestApi
     */
    public manifestManifestIdPassengerPassengerIdDelete(
        passengerId: string,
        manifestId: string,
        options?: any,
    ) {
        return ManifestApiFp(
            this.configuration,
        ).manifestManifestIdPassengerPassengerIdDelete(
            passengerId,
            manifestId,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * Updates the passenger
     * @summary Updates the passenger
     * @param {string} passengerId unique id to identify passenger
     * @param {string} manifestId unique id to identify manifest
     * @param {UpdatePassengerManifest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManifestApi
     */
    public manifestManifestIdPassengerUpdatePassengerIdPatch(
        passengerId: string,
        manifestId: string,
        body?: UpdatePassengerManifest,
        options?: any,
    ) {
        return ManifestApiFp(
            this.configuration,
        ).manifestManifestIdPassengerUpdatePassengerIdPatch(
            passengerId,
            manifestId,
            body,
            options,
        )(this.fetch, this.basePath);
    }
}
/**
 * PassengerApi - fetch parameter creator
 * @export
 */
export const PassengerApiFetchParamCreator = function (
    configuration?: Configuration,
) {
    return {
        /**
         * returns the age ranges
         * @summary gets the age ranges as id/description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passengerAgerangesGet(options: any = {}): FetchArgs {
            const localVarPath = `/passenger/ageranges`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * return passenger by passengerId
         * @summary get passenger
         * @param {string} passengerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passengerPassengerIdGet(
            passengerId: string,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'passengerId' is not null or undefined
            if (passengerId === null || passengerId === undefined) {
                throw new RequiredError(
                    "passengerId",
                    "Required parameter passengerId was null or undefined when calling passengerPassengerIdGet.",
                );
            }
            const localVarPath = `/passenger/{passengerId}`.replace(
                `{${"passengerId"}}`,
                encodeURIComponent(String(passengerId)),
            );
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * return a passenger by passenger Name
         * @summary return a passenger
         * @param {string} query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passengersQueryGet(query: string, options: any = {}): FetchArgs {
            // verify required parameter 'query' is not null or undefined
            if (query === null || query === undefined) {
                throw new RequiredError(
                    "query",
                    "Required parameter query was null or undefined when calling passengersQueryGet.",
                );
            }
            const localVarPath = `/passengers/{query}`.replace(
                `{${"query"}}`,
                encodeURIComponent(String(query)),
            );
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * PassengerApi - functional programming interface
 * @export
 */
export const PassengerApiFp = function (configuration?: Configuration) {
    return {
        /**
         * returns the age ranges
         * @summary gets the age ranges as id/description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passengerAgerangesGet(
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs =
                PassengerApiFetchParamCreator(
                    configuration,
                ).passengerAgerangesGet(options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * return passenger by passengerId
         * @summary get passenger
         * @param {string} passengerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passengerPassengerIdGet(
            passengerId: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = PassengerApiFetchParamCreator(
                configuration,
            ).passengerPassengerIdGet(passengerId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * return a passenger by passenger Name
         * @summary return a passenger
         * @param {string} query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passengersQueryGet(
            query: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = PassengerApiFetchParamCreator(
                configuration,
            ).passengersQueryGet(query, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * PassengerApi - factory interface
 * @export
 */
export const PassengerApiFactory = function (
    configuration?: Configuration,
    fetch?: FetchAPI,
    basePath?: string,
) {
    return {
        /**
         * returns the age ranges
         * @summary gets the age ranges as id/description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passengerAgerangesGet(options?: any) {
            return PassengerApiFp(configuration).passengerAgerangesGet(options)(
                fetch,
                basePath,
            );
        },
        /**
         * return passenger by passengerId
         * @summary get passenger
         * @param {string} passengerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passengerPassengerIdGet(passengerId: string, options?: any) {
            return PassengerApiFp(configuration).passengerPassengerIdGet(
                passengerId,
                options,
            )(fetch, basePath);
        },
        /**
         * return a passenger by passenger Name
         * @summary return a passenger
         * @param {string} query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passengersQueryGet(query: string, options?: any) {
            return PassengerApiFp(configuration).passengersQueryGet(
                query,
                options,
            )(fetch, basePath);
        },
    };
};

/**
 * PassengerApi - object-oriented interface
 * @export
 * @class PassengerApi
 * @extends {BaseAPI}
 */
export class PassengerApi extends BaseAPI {
    /**
     * returns the age ranges
     * @summary gets the age ranges as id/description
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PassengerApi
     */
    public passengerAgerangesGet(options?: any) {
        return PassengerApiFp(this.configuration).passengerAgerangesGet(
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * return passenger by passengerId
     * @summary get passenger
     * @param {string} passengerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PassengerApi
     */
    public passengerPassengerIdGet(passengerId: string, options?: any) {
        return PassengerApiFp(this.configuration).passengerPassengerIdGet(
            passengerId,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * return a passenger by passenger Name
     * @summary return a passenger
     * @param {string} query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PassengerApi
     */
    public passengersQueryGet(query: string, options?: any) {
        return PassengerApiFp(this.configuration).passengersQueryGet(
            query,
            options,
        )(this.fetch, this.basePath);
    }
}
/**
 * ReportApi - fetch parameter creator
 * @export
 */
export const ReportApiFetchParamCreator = function (
    configuration?: Configuration,
) {
    return {
        /**
         *
         * @summary returns a download of bookings within the provided parameters
         * @param {string} startDate
         * @param {string} endDate
         * @param {string} bookingStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportBookingsBookingStatusStartDateEndDateGet(
            startDate: string,
            endDate: string,
            bookingStatus: string,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'startDate' is not null or undefined
            if (startDate === null || startDate === undefined) {
                throw new RequiredError(
                    "startDate",
                    "Required parameter startDate was null or undefined when calling reportBookingsBookingStatusStartDateEndDateGet.",
                );
            }
            // verify required parameter 'endDate' is not null or undefined
            if (endDate === null || endDate === undefined) {
                throw new RequiredError(
                    "endDate",
                    "Required parameter endDate was null or undefined when calling reportBookingsBookingStatusStartDateEndDateGet.",
                );
            }
            // verify required parameter 'bookingStatus' is not null or undefined
            if (bookingStatus === null || bookingStatus === undefined) {
                throw new RequiredError(
                    "bookingStatus",
                    "Required parameter bookingStatus was null or undefined when calling reportBookingsBookingStatusStartDateEndDateGet.",
                );
            }
            const localVarPath =
                `/report/bookings/{bookingStatus}/{startDate}/{endDate}`
                    .replace(
                        `{${"startDate"}}`,
                        encodeURIComponent(String(startDate)),
                    )
                    .replace(
                        `{${"endDate"}}`,
                        encodeURIComponent(String(endDate)),
                    )
                    .replace(
                        `{${"bookingStatus"}}`,
                        encodeURIComponent(String(bookingStatus)),
                    );
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary returns a download of bookings within the provided parameters
         * @param {string} timeFrame
         * @param {string} bookingStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportBookingsTimeFrameBookingStatusGet(
            timeFrame: string,
            bookingStatus: string,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'timeFrame' is not null or undefined
            if (timeFrame === null || timeFrame === undefined) {
                throw new RequiredError(
                    "timeFrame",
                    "Required parameter timeFrame was null or undefined when calling reportBookingsTimeFrameBookingStatusGet.",
                );
            }
            // verify required parameter 'bookingStatus' is not null or undefined
            if (bookingStatus === null || bookingStatus === undefined) {
                throw new RequiredError(
                    "bookingStatus",
                    "Required parameter bookingStatus was null or undefined when calling reportBookingsTimeFrameBookingStatusGet.",
                );
            }
            const localVarPath = `/report/bookings/{timeFrame}/{bookingStatus}`
                .replace(
                    `{${"timeFrame"}}`,
                    encodeURIComponent(String(timeFrame)),
                )
                .replace(
                    `{${"bookingStatus"}}`,
                    encodeURIComponent(String(bookingStatus)),
                );
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary returns a download of bookings within the provided parameters
         * @param {string} startDate
         * @param {string} endDate
         * @param {string} bookingStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportBookingsBookingStatusStartDateEndDateGet(
            startDate: string,
            endDate: string,
            bookingStatus: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = ReportApiFetchParamCreator(
                configuration,
            ).reportBookingsBookingStatusStartDateEndDateGet(
                startDate,
                endDate,
                bookingStatus,
                options,
            );
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary returns a download of bookings within the provided parameters
         * @param {string} timeFrame
         * @param {string} bookingStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportBookingsTimeFrameBookingStatusGet(
            timeFrame: string,
            bookingStatus: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = ReportApiFetchParamCreator(
                configuration,
            ).reportBookingsTimeFrameBookingStatusGet(
                timeFrame,
                bookingStatus,
                options,
            );
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (
    configuration?: Configuration,
    fetch?: FetchAPI,
    basePath?: string,
) {
    return {
        /**
         *
         * @summary returns a download of bookings within the provided parameters
         * @param {string} startDate
         * @param {string} endDate
         * @param {string} bookingStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportBookingsBookingStatusStartDateEndDateGet(
            startDate: string,
            endDate: string,
            bookingStatus: string,
            options?: any,
        ) {
            return ReportApiFp(
                configuration,
            ).reportBookingsBookingStatusStartDateEndDateGet(
                startDate,
                endDate,
                bookingStatus,
                options,
            )(fetch, basePath);
        },
        /**
         *
         * @summary returns a download of bookings within the provided parameters
         * @param {string} timeFrame
         * @param {string} bookingStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportBookingsTimeFrameBookingStatusGet(
            timeFrame: string,
            bookingStatus: string,
            options?: any,
        ) {
            return ReportApiFp(
                configuration,
            ).reportBookingsTimeFrameBookingStatusGet(
                timeFrame,
                bookingStatus,
                options,
            )(fetch, basePath);
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     *
     * @summary returns a download of bookings within the provided parameters
     * @param {string} startDate
     * @param {string} endDate
     * @param {string} bookingStatus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportBookingsBookingStatusStartDateEndDateGet(
        startDate: string,
        endDate: string,
        bookingStatus: string,
        options?: any,
    ) {
        return ReportApiFp(
            this.configuration,
        ).reportBookingsBookingStatusStartDateEndDateGet(
            startDate,
            endDate,
            bookingStatus,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     *
     * @summary returns a download of bookings within the provided parameters
     * @param {string} timeFrame
     * @param {string} bookingStatus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportBookingsTimeFrameBookingStatusGet(
        timeFrame: string,
        bookingStatus: string,
        options?: any,
    ) {
        return ReportApiFp(
            this.configuration,
        ).reportBookingsTimeFrameBookingStatusGet(
            timeFrame,
            bookingStatus,
            options,
        )(this.fetch, this.basePath);
    }
}
/**
 * SkipperApi - fetch parameter creator
 * @export
 */
export const SkipperApiFetchParamCreator = function (
    configuration?: Configuration,
) {
    return {
        /**
         * Creates a skipper
         * @summary Create new skipper
         * @param {CreateSkipper} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperCreatePost(body: CreateSkipper, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling skipperCreatePost.",
                );
            }
            const localVarPath = `/skipper/create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"CreateSkipper" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete a skipper by skiperId
         * @summary delete a skipper by skipperId
         * @param {string} skipperId unique id to identify skipper
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperDeleteDelete(skipperId: string, options: any = {}): FetchArgs {
            // verify required parameter 'skipperId' is not null or undefined
            if (skipperId === null || skipperId === undefined) {
                throw new RequiredError(
                    "skipperId",
                    "Required parameter skipperId was null or undefined when calling skipperDeleteDelete.",
                );
            }
            const localVarPath = `/skipper/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "DELETE" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (skipperId !== undefined) {
                localVarQueryParameter["skipperId"] = skipperId;
            }

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * disables a skipper by skiperId
         * @summary disable skipper by skipperId
         * @param {string} skipperId unique id to identify skipper
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperDisablePatch(skipperId: string, options: any = {}): FetchArgs {
            // verify required parameter 'skipperId' is not null or undefined
            if (skipperId === null || skipperId === undefined) {
                throw new RequiredError(
                    "skipperId",
                    "Required parameter skipperId was null or undefined when calling skipperDisablePatch.",
                );
            }
            const localVarPath = `/skipper/disable`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "PATCH" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (skipperId !== undefined) {
                localVarQueryParameter["skipperId"] = skipperId;
            }

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns a skipper by skiperId
         * @summary return skipper by skipperId
         * @param {string} [skipperId] unique id to identify skipper
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperGet(skipperId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/skipper`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (skipperId !== undefined) {
                localVarQueryParameter["skipperId"] = skipperId;
            }

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update a skipper by skiperId
         * @summary update skipper by skipperId
         * @param {CreateSkipper} body
         * @param {string} [skipperId] unique id to identify skipper
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperPatch(
            body: CreateSkipper,
            skipperId?: string,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling skipperPatch.",
                );
            }
            const localVarPath = `/skipper`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "PATCH" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (skipperId !== undefined) {
                localVarQueryParameter["skipperId"] = skipperId;
            }

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"CreateSkipper" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * removes an unavailable day from the SkipperUnavailability Table
         * @summary removes an entry SkipperUnavailability Table
         * @param {string} skipperUnavailableId uniqueId of the unavailable entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperUnavailableDelete(
            skipperUnavailableId: string,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'skipperUnavailableId' is not null or undefined
            if (
                skipperUnavailableId === null ||
                skipperUnavailableId === undefined
            ) {
                throw new RequiredError(
                    "skipperUnavailableId",
                    "Required parameter skipperUnavailableId was null or undefined when calling skipperUnavailableDelete.",
                );
            }
            const localVarPath = `/skipper/unavailable`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "DELETE" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (skipperUnavailableId !== undefined) {
                localVarQueryParameter["skipperUnavailableId"] =
                    skipperUnavailableId;
            }

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists the days on which the skipper is unavailable
         * @summary Gets a skipper's unavailability
         * @param {string} [skipperId] unique id to identify skipper
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperUnavailableGet(
            skipperId?: string,
            options: any = {},
        ): FetchArgs {
            const localVarPath = `/skipper/unavailable`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (skipperId !== undefined) {
                localVarQueryParameter["skipperId"] = skipperId;
            }

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adds a unavailable day to the skipperUnavailable table
         * @summary Adds entry into SkipperUnavailable table
         * @param {string} date date to block out
         * @param {string} [userId] unique id to identify skipper
         * @param {string} [endDate] optional end date for range
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperUnavailablePost(
            date: string,
            userId?: string,
            endDate?: string,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError(
                    "date",
                    "Required parameter date was null or undefined when calling skipperUnavailablePost.",
                );
            }
            const localVarPath = `/skipper/unavailable`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (userId !== undefined) {
                localVarQueryParameter["userId"] = userId;
            }

            if (date !== undefined) {
                localVarQueryParameter["date"] = date;
            }

            if (endDate !== undefined) {
                localVarQueryParameter["endDate"] = endDate;
            }

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns a list of all skippers
         * @summary returns all skippers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skippersGet(options: any = {}): FetchArgs {
            const localVarPath = `/skippers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * SkipperApi - functional programming interface
 * @export
 */
export const SkipperApiFp = function (configuration?: Configuration) {
    return {
        /**
         * Creates a skipper
         * @summary Create new skipper
         * @param {CreateSkipper} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperCreatePost(
            body: CreateSkipper,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = SkipperApiFetchParamCreator(
                configuration,
            ).skipperCreatePost(body, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * delete a skipper by skiperId
         * @summary delete a skipper by skipperId
         * @param {string} skipperId unique id to identify skipper
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperDeleteDelete(
            skipperId: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = SkipperApiFetchParamCreator(
                configuration,
            ).skipperDeleteDelete(skipperId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * disables a skipper by skiperId
         * @summary disable skipper by skipperId
         * @param {string} skipperId unique id to identify skipper
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperDisablePatch(
            skipperId: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = SkipperApiFetchParamCreator(
                configuration,
            ).skipperDisablePatch(skipperId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * returns a skipper by skiperId
         * @summary return skipper by skipperId
         * @param {string} [skipperId] unique id to identify skipper
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperGet(
            skipperId?: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = SkipperApiFetchParamCreator(
                configuration,
            ).skipperGet(skipperId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * update a skipper by skiperId
         * @summary update skipper by skipperId
         * @param {CreateSkipper} body
         * @param {string} [skipperId] unique id to identify skipper
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperPatch(
            body: CreateSkipper,
            skipperId?: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = SkipperApiFetchParamCreator(
                configuration,
            ).skipperPatch(body, skipperId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * removes an unavailable day from the SkipperUnavailability Table
         * @summary removes an entry SkipperUnavailability Table
         * @param {string} skipperUnavailableId uniqueId of the unavailable entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperUnavailableDelete(
            skipperUnavailableId: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = SkipperApiFetchParamCreator(
                configuration,
            ).skipperUnavailableDelete(skipperUnavailableId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Lists the days on which the skipper is unavailable
         * @summary Gets a skipper's unavailability
         * @param {string} [skipperId] unique id to identify skipper
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperUnavailableGet(
            skipperId?: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = SkipperApiFetchParamCreator(
                configuration,
            ).skipperUnavailableGet(skipperId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Adds a unavailable day to the skipperUnavailable table
         * @summary Adds entry into SkipperUnavailable table
         * @param {string} date date to block out
         * @param {string} [userId] unique id to identify skipper
         * @param {string} [endDate] optional end date for range
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperUnavailablePost(
            date: string,
            userId?: string,
            endDate?: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = SkipperApiFetchParamCreator(
                configuration,
            ).skipperUnavailablePost(date, userId, endDate, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * returns a list of all skippers
         * @summary returns all skippers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skippersGet(
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs =
                SkipperApiFetchParamCreator(configuration).skippersGet(options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * SkipperApi - factory interface
 * @export
 */
export const SkipperApiFactory = function (
    configuration?: Configuration,
    fetch?: FetchAPI,
    basePath?: string,
) {
    return {
        /**
         * Creates a skipper
         * @summary Create new skipper
         * @param {CreateSkipper} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperCreatePost(body: CreateSkipper, options?: any) {
            return SkipperApiFp(configuration).skipperCreatePost(body, options)(
                fetch,
                basePath,
            );
        },
        /**
         * delete a skipper by skiperId
         * @summary delete a skipper by skipperId
         * @param {string} skipperId unique id to identify skipper
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperDeleteDelete(skipperId: string, options?: any) {
            return SkipperApiFp(configuration).skipperDeleteDelete(
                skipperId,
                options,
            )(fetch, basePath);
        },
        /**
         * disables a skipper by skiperId
         * @summary disable skipper by skipperId
         * @param {string} skipperId unique id to identify skipper
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperDisablePatch(skipperId: string, options?: any) {
            return SkipperApiFp(configuration).skipperDisablePatch(
                skipperId,
                options,
            )(fetch, basePath);
        },
        /**
         * returns a skipper by skiperId
         * @summary return skipper by skipperId
         * @param {string} [skipperId] unique id to identify skipper
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperGet(skipperId?: string, options?: any) {
            return SkipperApiFp(configuration).skipperGet(skipperId, options)(
                fetch,
                basePath,
            );
        },
        /**
         * update a skipper by skiperId
         * @summary update skipper by skipperId
         * @param {CreateSkipper} body
         * @param {string} [skipperId] unique id to identify skipper
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperPatch(body: CreateSkipper, skipperId?: string, options?: any) {
            return SkipperApiFp(configuration).skipperPatch(
                body,
                skipperId,
                options,
            )(fetch, basePath);
        },
        /**
         * removes an unavailable day from the SkipperUnavailability Table
         * @summary removes an entry SkipperUnavailability Table
         * @param {string} skipperUnavailableId uniqueId of the unavailable entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperUnavailableDelete(skipperUnavailableId: string, options?: any) {
            return SkipperApiFp(configuration).skipperUnavailableDelete(
                skipperUnavailableId,
                options,
            )(fetch, basePath);
        },
        /**
         * Lists the days on which the skipper is unavailable
         * @summary Gets a skipper's unavailability
         * @param {string} [skipperId] unique id to identify skipper
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperUnavailableGet(skipperId?: string, options?: any) {
            return SkipperApiFp(configuration).skipperUnavailableGet(
                skipperId,
                options,
            )(fetch, basePath);
        },
        /**
         * Adds a unavailable day to the skipperUnavailable table
         * @summary Adds entry into SkipperUnavailable table
         * @param {string} date date to block out
         * @param {string} [userId] unique id to identify skipper
         * @param {string} [endDate] optional end date for range
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipperUnavailablePost(
            date: string,
            userId?: string,
            endDate?: string,
            options?: any,
        ) {
            return SkipperApiFp(configuration).skipperUnavailablePost(
                date,
                userId,
                endDate,
                options,
            )(fetch, basePath);
        },
        /**
         * returns a list of all skippers
         * @summary returns all skippers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skippersGet(options?: any) {
            return SkipperApiFp(configuration).skippersGet(options)(
                fetch,
                basePath,
            );
        },
    };
};

/**
 * SkipperApi - object-oriented interface
 * @export
 * @class SkipperApi
 * @extends {BaseAPI}
 */
export class SkipperApi extends BaseAPI {
    /**
     * Creates a skipper
     * @summary Create new skipper
     * @param {CreateSkipper} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkipperApi
     */
    public skipperCreatePost(body: CreateSkipper, options?: any) {
        return SkipperApiFp(this.configuration).skipperCreatePost(
            body,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * delete a skipper by skiperId
     * @summary delete a skipper by skipperId
     * @param {string} skipperId unique id to identify skipper
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkipperApi
     */
    public skipperDeleteDelete(skipperId: string, options?: any) {
        return SkipperApiFp(this.configuration).skipperDeleteDelete(
            skipperId,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * disables a skipper by skiperId
     * @summary disable skipper by skipperId
     * @param {string} skipperId unique id to identify skipper
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkipperApi
     */
    public skipperDisablePatch(skipperId: string, options?: any) {
        return SkipperApiFp(this.configuration).skipperDisablePatch(
            skipperId,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * returns a skipper by skiperId
     * @summary return skipper by skipperId
     * @param {string} [skipperId] unique id to identify skipper
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkipperApi
     */
    public skipperGet(skipperId?: string, options?: any) {
        return SkipperApiFp(this.configuration).skipperGet(skipperId, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * update a skipper by skiperId
     * @summary update skipper by skipperId
     * @param {CreateSkipper} body
     * @param {string} [skipperId] unique id to identify skipper
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkipperApi
     */
    public skipperPatch(
        body: CreateSkipper,
        skipperId?: string,
        options?: any,
    ) {
        return SkipperApiFp(this.configuration).skipperPatch(
            body,
            skipperId,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * removes an unavailable day from the SkipperUnavailability Table
     * @summary removes an entry SkipperUnavailability Table
     * @param {string} skipperUnavailableId uniqueId of the unavailable entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkipperApi
     */
    public skipperUnavailableDelete(
        skipperUnavailableId: string,
        options?: any,
    ) {
        return SkipperApiFp(this.configuration).skipperUnavailableDelete(
            skipperUnavailableId,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * Lists the days on which the skipper is unavailable
     * @summary Gets a skipper's unavailability
     * @param {string} [skipperId] unique id to identify skipper
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkipperApi
     */
    public skipperUnavailableGet(skipperId?: string, options?: any) {
        return SkipperApiFp(this.configuration).skipperUnavailableGet(
            skipperId,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * Adds a unavailable day to the skipperUnavailable table
     * @summary Adds entry into SkipperUnavailable table
     * @param {string} date date to block out
     * @param {string} [userId] unique id to identify skipper
     * @param {string} [endDate] optional end date for range
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkipperApi
     */
    public skipperUnavailablePost(
        date: string,
        userId?: string,
        endDate?: string,
        options?: any,
    ) {
        return SkipperApiFp(this.configuration).skipperUnavailablePost(
            date,
            userId,
            endDate,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * returns a list of all skippers
     * @summary returns all skippers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkipperApi
     */
    public skippersGet(options?: any) {
        return SkipperApiFp(this.configuration).skippersGet(options)(
            this.fetch,
            this.basePath,
        );
    }
}
/**
 * TestApi - fetch parameter creator
 * @export
 */
export const TestApiFetchParamCreator = function (
    configuration?: Configuration,
) {
    return {
        /**
         *
         * @summary returns test date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testGet(options: any = {}): FetchArgs {
            const localVarPath = `/test`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * TestApi - functional programming interface
 * @export
 */
export const TestApiFp = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary returns test date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testGet(
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs =
                TestApiFetchParamCreator(configuration).testGet(options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * TestApi - factory interface
 * @export
 */
export const TestApiFactory = function (
    configuration?: Configuration,
    fetch?: FetchAPI,
    basePath?: string,
) {
    return {
        /**
         *
         * @summary returns test date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testGet(options?: any) {
            return TestApiFp(configuration).testGet(options)(fetch, basePath);
        },
    };
};

/**
 * TestApi - object-oriented interface
 * @export
 * @class TestApi
 * @extends {BaseAPI}
 */
export class TestApi extends BaseAPI {
    /**
     *
     * @summary returns test date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public testGet(options?: any) {
        return TestApiFp(this.configuration).testGet(options)(
            this.fetch,
            this.basePath,
        );
    }
}
/**
 * UserApi - fetch parameter creator
 * @export
 */
export const UserApiFetchParamCreator = function (
    configuration?: Configuration,
) {
    return {
        /**
         * returns all admins
         * @summary returns a list of admins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        admins(options: any = {}): FetchArgs {
            const localVarPath = `/admins`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * checks if a user exists, and returns true if exist and is a client
         * @summary returns an indication of existence of a client
         * @param {string} email the email address to check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientExists(email: string, options: any = {}): FetchArgs {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError(
                    "email",
                    "Required parameter email was null or undefined when calling clientExists.",
                );
            }
            const localVarPath = `/user/clientExists/{email}`.replace(
                `{${"email"}}`,
                encodeURIComponent(String(email)),
            );
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns all users
         * @summary returns a list of clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clients(options: any = {}): FetchArgs {
            const localVarPath = `/clients`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new user
         * @summary Creates a new user
         * @param {CreateUser} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(body: CreateUser, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling createUser.",
                );
            }
            const localVarPath = `/user/create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "POST" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"CreateUser" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * deletes a specified user
         * @summary deletes a user
         * @param {number} userId a unique number to identify a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: number, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError(
                    "userId",
                    "Required parameter userId was null or undefined when calling deleteUser.",
                );
            }
            const localVarPath = `/user/delete/{userId}`.replace(
                `{${"userId"}}`,
                encodeURIComponent(String(userId)),
            );
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "DELETE" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * disables a user
         * @summary Disables a user
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableUser(userId: number, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError(
                    "userId",
                    "Required parameter userId was null or undefined when calling disableUser.",
                );
            }
            const localVarPath = `/user/disable/{userId}`.replace(
                `{${"userId"}}`,
                encodeURIComponent(String(userId)),
            );
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "PATCH" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * updates a specified user
         * @summary updates a user
         * @param {CreateUser} body
         * @param {string} userId a unique number to identify a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(
            body: CreateUser,
            userId: string,
            options: any = {},
        ): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    "body",
                    "Required parameter body was null or undefined when calling updateUser.",
                );
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError(
                    "userId",
                    "Required parameter userId was null or undefined when calling updateUser.",
                );
            }
            const localVarPath = `/user/{userId}`.replace(
                `{${"userId"}}`,
                encodeURIComponent(String(userId)),
            );
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "PATCH" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter["Content-Type"] = "application/json";

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );
            const needsSerialization =
                <any>"CreateUser" !== "string" ||
                localVarRequestOptions.headers["Content-Type"] ===
                    "application/json";
            localVarRequestOptions.body = needsSerialization
                ? JSON.stringify(body || {})
                : body || "";

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns a specified user
         * @summary returns a user
         * @param {string} userId a unique number to identify a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        user(userId: string, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError(
                    "userId",
                    "Required parameter userId was null or undefined when calling user.",
                );
            }
            const localVarPath = `/user/{userId}`.replace(
                `{${"userId"}}`,
                encodeURIComponent(String(userId)),
            );
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign(
                { method: "GET" },
                options,
            );
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarUrlObj.query = Object.assign(
                {},
                localVarUrlObj.query,
                localVarQueryParameter,
                options.query,
            );
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(
                {},
                localVarHeaderParameter,
                options.headers,
            );

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
    return {
        /**
         * returns all admins
         * @summary returns a list of admins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        admins(
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs =
                UserApiFetchParamCreator(configuration).admins(options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * checks if a user exists, and returns true if exist and is a client
         * @summary returns an indication of existence of a client
         * @param {string} email the email address to check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientExists(
            email: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = UserApiFetchParamCreator(
                configuration,
            ).clientExists(email, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * returns all users
         * @summary returns a list of clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clients(
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs =
                UserApiFetchParamCreator(configuration).clients(options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Creates a new user
         * @summary Creates a new user
         * @param {CreateUser} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(
            body: CreateUser,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = UserApiFetchParamCreator(
                configuration,
            ).createUser(body, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * deletes a specified user
         * @summary deletes a user
         * @param {number} userId a unique number to identify a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(
            userId: number,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = UserApiFetchParamCreator(
                configuration,
            ).deleteUser(userId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * disables a user
         * @summary Disables a user
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableUser(
            userId: number,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = UserApiFetchParamCreator(
                configuration,
            ).disableUser(userId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * updates a specified user
         * @summary updates a user
         * @param {CreateUser} body
         * @param {string} userId a unique number to identify a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(
            body: CreateUser,
            userId: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = UserApiFetchParamCreator(
                configuration,
            ).updateUser(body, userId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * returns a specified user
         * @summary returns a user
         * @param {string} userId a unique number to identify a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        user(
            userId: string,
            options?: any,
        ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResponse> {
            const localVarFetchArgs = UserApiFetchParamCreator(
                configuration,
            ).user(userId, options);
            return (
                fetch: FetchAPI = portableFetch,
                basePath: string = BASE_PATH,
            ) => {
                return fetch(
                    basePath + localVarFetchArgs.url,
                    localVarFetchArgs.options,
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (
    configuration?: Configuration,
    fetch?: FetchAPI,
    basePath?: string,
) {
    return {
        /**
         * returns all admins
         * @summary returns a list of admins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        admins(options?: any) {
            return UserApiFp(configuration).admins(options)(fetch, basePath);
        },
        /**
         * checks if a user exists, and returns true if exist and is a client
         * @summary returns an indication of existence of a client
         * @param {string} email the email address to check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientExists(email: string, options?: any) {
            return UserApiFp(configuration).clientExists(email, options)(
                fetch,
                basePath,
            );
        },
        /**
         * returns all users
         * @summary returns a list of clients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clients(options?: any) {
            return UserApiFp(configuration).clients(options)(fetch, basePath);
        },
        /**
         * Creates a new user
         * @summary Creates a new user
         * @param {CreateUser} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(body: CreateUser, options?: any) {
            return UserApiFp(configuration).createUser(body, options)(
                fetch,
                basePath,
            );
        },
        /**
         * deletes a specified user
         * @summary deletes a user
         * @param {number} userId a unique number to identify a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: number, options?: any) {
            return UserApiFp(configuration).deleteUser(userId, options)(
                fetch,
                basePath,
            );
        },
        /**
         * disables a user
         * @summary Disables a user
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableUser(userId: number, options?: any) {
            return UserApiFp(configuration).disableUser(userId, options)(
                fetch,
                basePath,
            );
        },
        /**
         * updates a specified user
         * @summary updates a user
         * @param {CreateUser} body
         * @param {string} userId a unique number to identify a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(body: CreateUser, userId: string, options?: any) {
            return UserApiFp(configuration).updateUser(
                body,
                userId,
                options,
            )(fetch, basePath);
        },
        /**
         * returns a specified user
         * @summary returns a user
         * @param {string} userId a unique number to identify a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        user(userId: string, options?: any) {
            return UserApiFp(configuration).user(userId, options)(
                fetch,
                basePath,
            );
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * returns all admins
     * @summary returns a list of admins
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public admins(options?: any) {
        return UserApiFp(this.configuration).admins(options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * checks if a user exists, and returns true if exist and is a client
     * @summary returns an indication of existence of a client
     * @param {string} email the email address to check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public clientExists(email: string, options?: any) {
        return UserApiFp(this.configuration).clientExists(email, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * returns all users
     * @summary returns a list of clients
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public clients(options?: any) {
        return UserApiFp(this.configuration).clients(options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * Creates a new user
     * @summary Creates a new user
     * @param {CreateUser} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUser(body: CreateUser, options?: any) {
        return UserApiFp(this.configuration).createUser(body, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * deletes a specified user
     * @summary deletes a user
     * @param {number} userId a unique number to identify a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(userId: number, options?: any) {
        return UserApiFp(this.configuration).deleteUser(userId, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * disables a user
     * @summary Disables a user
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public disableUser(userId: number, options?: any) {
        return UserApiFp(this.configuration).disableUser(userId, options)(
            this.fetch,
            this.basePath,
        );
    }

    /**
     * updates a specified user
     * @summary updates a user
     * @param {CreateUser} body
     * @param {string} userId a unique number to identify a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(body: CreateUser, userId: string, options?: any) {
        return UserApiFp(this.configuration).updateUser(
            body,
            userId,
            options,
        )(this.fetch, this.basePath);
    }

    /**
     * returns a specified user
     * @summary returns a user
     * @param {string} userId a unique number to identify a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public user(userId: string, options?: any) {
        return UserApiFp(this.configuration).user(userId, options)(
            this.fetch,
            this.basePath,
        );
    }
}

/**
 *
 * @export
 * @interface CompleteBooking
 */
export interface CompleteBooking {
    /**
     *
     * @type {string}
     * @memberof CompleteBooking
     */
    bookingId?: string;
}
